import { Component } from 'preact'
import cx from 'classnames'
import Caption from '../textNodes/caption'

const DEFAULT_UNITS = I18n.t('subscription.unitsOneTime')

class Price extends Component {
  get spaceBeforeUnits() {
    const thinSpace = ' '
    const regularSpace = ' '
    const { hasCompactUnitsView, leftUnits, spaceTypeBeforeUnits } = this.props
    const space = spaceTypeBeforeUnits === 'regular' ? regularSpace : thinSpace

    if (hasCompactUnitsView) return '<span class="is__hiddenWhenCompact"> </span>'

    return leftUnits ? '' : space
  }

  get priceClassNames() {
    const { className, size, small, large, leftUnits } = this.props

    return cx(
      'price',
      className,
      {
        [`is__${size}`]: size,
        'is__smallSize-legacy': small,
        'is__largeSize-legacy': large,
        has__leftUnits: !!leftUnits,
      }
    )
  }

  get unitsClassNames() {
    const { unitsSize, smallUnits, hasCompactUnitsView } = this.props

    return cx(
      'price-units',
      {
        [`is__${unitsSize}`]: unitsSize,
        'is__smallUnits-legacy': smallUnits,
        is__compact: hasCompactUnitsView,
      },
    )
  }

  get units() {
    const { leftUnits, units } = this.props

    return leftUnits ? units : (units || DEFAULT_UNITS)
  }

  get amount() {
    if (this.props.amount === null || this.props.amount === undefined) return undefined

    return String(this.props.amount)
  }

  render(props) {
    return (
      <div className={ this.priceClassNames }>
        { props.label &&
          <Caption html={ props.label } />
        }

        <div className={ cx('price-amount', { is__highlighted: props.highlighted }) }>
          { props.leftUnits &&
            <span className={ this.unitsClassNames }>{ props.leftUnits }</span>
          }

          <span dangerouslySetInnerHTML={{ __html: this.amount }}></span>

          { this.units &&
            <span className={ this.unitsClassNames }
              dangerouslySetInnerHTML={{ __html: `${this.spaceBeforeUnits}${this.units}` }}>
            </span>
          }
        </div>

        { props.footnote && <Caption html={ props.footnote } /> }
      </div>
    )
  }
}

module.exports = Price
