const getAvailableTabs = ({ subscription = {}, prices = {}, hasCompanyTabOnly }) => {
  if (hasCompanyTabOnly) return ['company']

  const canUpgrade = !!prices.specialOffer
  const shouldDisplayCompanyTab = subscription.subscription_type === 'company' || subscription.product === 'course'

  return [
    canUpgrade ? 'upgrade' : 'self',
    'gift',
    shouldDisplayCompanyTab ? 'company' : '',
  ].filter(tab => tab)
}

export default getAvailableTabs
