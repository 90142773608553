import { Component } from 'preact'
import cx from 'classnames'
import camelize from 'lib/camelize'

import { Heading2 } from '../textNodes'
import { fetchEnrollmentRating } from './helpers'
import SchoolRatingsLevelLink from './schoolRatingsLevelLink'
import SchoolRatingsContent from './schoolRatingsContent'

const ALL_LEVELS = [1, 2, 3]

export default class SchoolRatings extends Component {
  constructor(props) {
    super(props)

    const currentLevel = props.levels.slice(-1)[0]

    this.state = {
      activeLevel: currentLevel.number,
      loadedEnrollments: {},
    }
  }

  componentDidMount() {
    this.props.levels.forEach(({ enrollment }) => {
      fetchEnrollmentRating(enrollment)
        .then(data => {
          this.setEnrollment(enrollment, data.map(student => camelize(student)))
        })
    })
  }

  setEnrollment(enrollment, data) {
    const loadedEnrollments = {
      ...this.state.loadedEnrollments,
      [enrollment]: data,
    }

    this.setState({ loadedEnrollments })
  }

  setLevel(level) {
    const alreadySet = this.state.activeLevel === level

    if (!this.getLevelData(level) || alreadySet) return

    this.setState({ activeLevel: level })
  }

  getLevelData(level) {
    return this.props.levels.find(levelData => levelData.number === level)
  }

  get googleLink() {
    const activeLevelData = this.getLevelData(this.state.activeLevel)

    if (!activeLevelData || !activeLevelData.googleLink) return null

    return I18n.t('schoolRating.googleLink', { googleLink: activeLevelData.googleLink })
  }

  get levelCaption() {
    return I18n.t(`schoolRating.caption_level${this.state.activeLevel}`) || null
  }

  get caption() {
    return [this.levelCaption, this.googleLink].filter(part => !!part).join(' <br>')
  }

  render() {
    const { activeLevel, loadedEnrollments } = this.state
    const activeLevelEnrollment = (this.getLevelData(activeLevel) || {}).enrollment
    const classNames = cx('schoolRatings', `is__level${activeLevel}`)

    return (
      <div className={ classNames }>
        <div className="schoolRatingInfo">
          <Heading2
            className={ this.props.headingClassName }
            html={ this.props.title || I18n.t('schoolRating.defaultTitle') } />

          <div className="recycledLinks">
            <div className="recycedLinks-in">
              { ALL_LEVELS.map(level => (
                <SchoolRatingsLevelLink
                  level={ level }
                  isActive={ activeLevel === level }
                  isDisabled={ !this.getLevelData(level) }
                  onClick={ () => this.setLevel(level) }/>
              ))}
            </div>
          </div>

          { this.caption && <p dangerouslySetInnerHTML={ { __html: this.caption } }></p> }
        </div>

        <div className="schoolRatingInfo-content">
          <SchoolRatingsContent
            level={ activeLevel }
            students={ loadedEnrollments[activeLevelEnrollment] }/>
        </div>
      </div>
    )
  }
}
