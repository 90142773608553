const COVER_SIZES = {
  full: {
    desktop: { video: [154, 87], image: [38, 74] },
    laptop: { video: [156, 88], image: [38, 75] },
    mobile: { video: [205, 117], image: [50, 100] },
  },
  half: {
    desktop: { video: [214, 121], image: [53, 107] },
    laptop: { video: [195, 110], image: [48, 94] },
  },
}
const DEVICES = {
  full: ['desktop', 'laptop', 'mobile'],
  half: ['desktop', 'laptop'],
}
const LectureSpacerCover = ({ href, lectureKey, size }) => {
  const devices = DEVICES[size]
  const coverSizes = COVER_SIZES[size]
  const host = `${window.location.protocol}//${window.location.host}`
  const assetPath = `${host}/lectures/${lectureKey}/files`
  const imgSrc = `/lectures/${lectureKey}/files/_cover@.5x.jpg`
  const videoSrc = `${assetPath}/teaser-cover.mp4`

  return (
    <a
      style={{ position: 'relative' }}
      href={ href }
      className="lectureSpacer-cover doubleHover no-u" >
        { devices.map(device =>
            <div // eslint-disable-line implicit-arrow-linebreak
              className={`lectureSpacer-coverMedia module device device__${device}`}
              style={{ width: coverSizes[device].video[0], height: coverSizes[device].video[1] }}>
              <div className='lectureSpacer-coverVideoWrapper' style={ { backgroundImage: `url(${imgSrc})` } } >
                <video autoplay muted loop src={ videoSrc } poster={ imgSrc } />
              </div>
              <div
                className="image"
                style={ {
                  backgroundImage: `url(${imgSrc})`,
                  width: coverSizes[device].image[0],
                  height: coverSizes[device].image[1],
                } }
              />
            </div>)
        }
    </a>
  )
}

export default LectureSpacerCover
