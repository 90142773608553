const tinycolor = require('../vendor/tinycolor')

const DEFAULT_COLOR = {
  textColor: '#000',
  keyColor: 'rgb(227, 0, 22)',
}

const getColorValueFromHtml = (style, colorName) => {
  const styleStartsWithRoot = style.substring(style.search(/:root\s*\{/))
  const rootStyle = styleStartsWithRoot.substring(0, styleStartsWithRoot.indexOf('}') + 1)
  const colorRegExp = new RegExp('--' + colorName + ':')
  const commentColorRegExp = new RegExp('\/\/\s*--' + colorName + ':')

  const colorStyle = rootStyle.split('\n')
    .filter(line => colorRegExp.test(line))
    .filter(line => !/var\(/.test(line))
    .filter(line => !commentColorRegExp.test(line))
    .slice(-1)[0] || DEFAULT_COLOR[colorName]

  const color = colorStyle
    .replace(`--${colorName}:`, '')
    .replace(';', '')
    .trim()

  return color
}

module.exports = {
  isLight: color => tinycolor(color).isLight(),
  getBrightness: color => tinycolor(color).getBrightness(),
  brighten: (color, amount) => tinycolor(color).brighten(amount),
  darken: (color, amount) => tinycolor(color).darken(amount),
  setAlpha: (color, opacity) => tinycolor(color).setAlpha(opacity).toRgbString(),
  getLuminance: color => tinycolor(color).getLuminance(),
  getColorValueFromHtml,
}
