import { Component } from 'preact'
import { connect } from 'preact-redux'

import { birmanize } from 'lib/textHelper'
import { moscowifyLocal } from '../../calendar/timezoneUtils'

import NewPaybarFieldsetHeading from '../newPaybarFieldsetHeading'

class NewPaybarCourseHowToInfo extends Component {
  render() {
    const invoiceLastDate = birmanize(moscowifyLocal(new Date(this.props.productDetails.starts_at)), { format: 'fullMonthDateOnly' })
    let invoiceSuffix = ''
    if (this.props.companyPaymentType === 'invoice') {
      invoiceSuffix = this.props.productDetails.missed_invoice_payment_deadline ?
        'BeforeDeadline' : 'OnWorkdays'
    }

    return (
      <NewPaybarFieldsetHeading
        className="is__howTo"
        text={ I18n.t('newPaybar.course.howTo') }
        caption={ I18n.t(`newPaybar.course.howToCaption.remote.${this.props.participantType}.${this.props.companyPaymentType}${invoiceSuffix}`, { date: invoiceLastDate }) } />
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    productDetails: newPaybar.productDetails,
    companyPaymentType: newPaybar.companyPaymentType,
    type: newPaybar.type,
    participantType: newPaybar.participantType,
  }
}

export default connect(mapStateToProps)(NewPaybarCourseHowToInfo)
