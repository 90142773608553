import { Component } from 'preact'
import { connect } from 'preact-redux'

import expandScreenToFlags from '../expandScreenToFlags'
import isLaunchpad from '../isLaunchpad'

import NewPaybarPayer from '../newPaybarPayer'
import NewPaybarPersonalForm from '../newPaybarPersonalForm'
import NewPaybarOverlay from '../newPaybarOverlay'
import NewPaybarLaunchpad from '../newPaybarLaunchpad'
import NewPaybarTitle from '../newPaybarTitle'
import NewPaybarCourseCaption from './newPaybarCourseCaption'
import NewPaybarCoursePricing from './newPaybarCoursePricing'
import NewPaybarTabs from '../newPaybarTabs'
import NewPaybarPreorderLabel from '../newPaybarPreorderLabel'

class NewPaybarCourse extends Component {
  render(props) {
    const shouldDisplayPricing = props.isPurchaseScreen || props.isInitialScreen

    return (
      <div className="newPaybar-infoIn module">
        { !props.isSuccessScreen &&
          <NewPaybarTabs device="mobile" type={ props.type } product={ props.product } />
        }

        { !props.isSubscriptionScreen && props.labelPreorder &&
          <div className="newPaybar-label">
            <NewPaybarPreorderLabel text={ props.labelPreorder }/>
          </div>
        }

        { !props.isSubscriptionScreen &&
          <NewPaybarTitle
            className="newPaybarTitle newPaybarCourseTitle module device device__mobile"
            type={ props.type }
            screen={ props.screen }
            product={ props.product }
            productTitle={ props.productTitle } />
        }

        { props.isSuccessScreen &&
          <NewPaybarOverlay className="device device__mobile" />
        }

        { isLaunchpad(props) &&
          <div className="newPaybarLaunchpadMobileWrapper module device device__mobile">
            <NewPaybarLaunchpad />
          </div>
        }

        <div className="newPaybarCoursePricingAndInfo">
          { shouldDisplayPricing &&
            <NewPaybarCoursePricing
              type={ props.type }
              prices={ props.prices }
              startsAt={ props.productDetails.starts_at }
              seatsLeft={ props.productDetails.seats_left }
              isStudent={ props.isStudent } />
          }

          <NewPaybarCourseCaption
            isPurchaseScreen={ props.isPurchaseScreen }
            isInitialScreen={ props.isInitialScreen }
            product={ props.product }
            requirements={ props.productDetails.requirements }
            dates={ props.productDetails.dates }
          />
        </div>

        { props.isPurchaseScreen &&
          <NewPaybarPayer payerRef={ props.payerRef } isCompact />
        }

        { !props.isSuccessScreen &&
          <NewPaybarPersonalForm
            subscriptionUrl={ props.subscriptionUrl }
            scrollableRef={ props.scrollableRef } />
        }

        { this.props.children }
      </div>
    )
  }
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    type: newPaybar.type,
    product: newPaybar.product,
    productTitle: newPaybar.productTitle,
    prices: newPaybar.prices,
    subscription: newPaybar.subscription,
    screen: newPaybar.screen,
    isUserAuthenticated: newPaybar.isUserAuthenticated,
    paymentType: newPaybar.paymentType,
    isStudent: newPaybar.isStudent,
    productDetails: newPaybar.productDetails,
    ...expandScreenToFlags(newPaybar.screen),
  }
}

export default connect(mapStateToProps)(NewPaybarCourse)
