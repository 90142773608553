import { connect } from 'preact-redux'

import { Caption, TextNode } from '../../textNodes'
import { FormInput, FormTextarea } from '../../form'

import { formatPrice } from 'lib/textHelper'

import NewPaybarPersonalFieldsetBase from '../newPaybarPersonalFieldsetBase'
import NewPaybarPaymentSwitch from './newPaybarPaymentSwitch'
import NewPaybarEnrollmentSwitch from './newPaybarEnrollmentSwitch'
import NewPaybarTelegramInput from '../newPaybarTelegramInput'

import {
  addDirtyForm,
  removeDirtyForm,
  setFormValidity,
  setPaymentType,
  setEnrollment,
} from '../newPaybarActions'

import { getSchoolKeyBySubscription } from './schoolHelpers'

class NewPaybarPersonalFieldset extends NewPaybarPersonalFieldsetBase {
  constructor(props) {
    super(props)

    this.validityStates = {
      payerName: !!this.props.userName,
      presenteeName: false,
      presenteeEmail: false,
      city: !!this.props.userCity,
      profession: !!this.props.userProfession,
      about: false,
      tel: false,
      telegram: false,
      portfolio: false,
      taskResult: false,
    }

    this.validityMaps = {
      self: ['payerName', 'city', 'profession', 'tel'],
      selfGrant: ['payerName', 'city', 'profession', 'about', 'tel', 'portfolio'],
    }

    this.rememberedHiddenInputs = ['enrollment', 'paymentType']

    this.setGrantEnrollment = this.setGrantEnrollment.bind(this)
    this.setDefaultEnrollment = this.setDefaultEnrollment.bind(this)
    this.setOneTime = this.setOneTime.bind(this)
    this.setMonthly = this.setMonthly.bind(this)

    this.GrantEnrollmentFields = this.GrantEnrollmentFields.bind(this)
  }

  get isValid() {
    if (this.props.hasTerms && !this.isAgreedToTerms) return false

    const validityMap = this.props.enrollment === 'grant' ? 'selfGrant' : 'self'

    return this.validityMaps[validityMap]
      .every(field => this.validityStates[field])
  }

  componentDidUpdate() {
    this.props.setFormValidity({ isValid: this.isValid })
    this.rememberedHiddenInputs.forEach(input => this.remember(input))
    this.saveAgreedToTerms()
  }

  setGrantEnrollment() {
    this.props.setEnrollment('grant')
  }

  setDefaultEnrollment() {
    this.props.setEnrollment('default')
  }

  setOneTime() {
    this.props.setPaymentType('oneTime')
  }

  setMonthly() {
    this.props.setPaymentType('monthly')
  }

  get enrollmentCaption() {
    if (this.props.enrollment === 'default') return I18n.t('newPaybar.school.defaultEnrollmentCaption')

    const schoolKey = getSchoolKeyBySubscription(this.props.subscription)
    const contestLink = `/school/contest-${schoolKey}/`

    return I18n.t(`newPaybar.school.grantEnrollmentCaption.${schoolKey}`, { contestLink })
  }

  get paymentCaption() {
    const isOneTime = this.props.paymentType === 'oneTime'
    const prices = isOneTime ? this.props.prices : this.props.prices.monthly
    const price = formatPrice(prices.oneTime)
    const maxPrice = formatPrice(this.props.prices.monthly.total)

    return I18n.t(`newPaybar.school.${isOneTime ? 'oneTime' : 'monthly'}Caption`, { price, maxPrice })
  }

  get portfolioCaption() {
    const schoolKey = getSchoolKeyBySubscription(this.props.subscription)

    return I18n.t(`newPaybar.school.portfolioCaption.${schoolKey}`)
  }

  render() {
    const { GrantEnrollmentFields } = this

    return (
      <div className="newPaybarPersonalFieldset" ref={ this.bindEl }>
        <div className="newPaybarPersonalFieldset-row is__switch has__caption">
          <NewPaybarEnrollmentSwitch
            enrollment={ this.props.enrollment }
            onSetGrantEnrollment={ this.setGrantEnrollment }
            onSetDefaultEnrollment={ this.setDefaultEnrollment } />
          <Caption html={ this.enrollmentCaption }></Caption>
        </div>

        <input type="hidden" name="paymentType" value={ this.props.paymentType } />
        <input type="hidden" name="enrollment" value={ this.props.enrollment } />

        { !this.props.userName &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.name') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="payerName"
              type="text"
              autoComplete="name"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.payerName }
              ref={ ref => this.fieldRefs.payerName = ref } />
          </label>
        }

        <label className="newPaybarPersonalFieldset-row has__inlineCaption">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.school.tel') }
          </TextNode>
          <div className="newPaybarPersonalFieldset-inputWithCaption">
            <FormInput className="newPaybarPersonalFieldset-input"
              name="tel"
              type="tel"
              autoComplete="tel"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.tel || this.props.userTel }
              ref={ ref => this.fieldRefs.tel = ref } />
            <Caption html={ I18n.t('newPaybar.school.telCaption') }></Caption>
          </div>
        </label>

        <NewPaybarTelegramInput
          name="telegram"
          onInput={ this.onInput }
          onValidityChange={ this.onInputValidityChange }
          initialValue={ this.rememberedFields.telegram || this.props.userTelegram }
          ref={ ref => this.fieldRefs.telegram = ref } />

        { !this.props.userCity &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.city') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="city"
              type="text"
              autoComplete="city"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.city }
              ref={ ref => this.fieldRefs.city = ref } />
          </label>
        }

        { !this.props.userProfession &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="profession"
              type="text"
              autoComplete="organization-title"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.profession }
              ref={ ref => this.fieldRefs.profession = ref } />
          </label>
        }

        { this.props.enrollment === 'grant' &&
          <GrantEnrollmentFields/>
        }

        <div className="newPaybarPersonalFieldset-row is__switch has__caption">
          <NewPaybarPaymentSwitch
            payment={ this.props.paymentType }
            onSetMonthly={ this.setMonthly }
            onSetOneTime={ this.setOneTime } />
          <Caption html={ this.paymentCaption }></Caption>
        </div>
      </div>
    )
  }

  GrantEnrollmentFields() {
    return (
      <div className="newPaybarPersonalFieldset-section">
        <label className="newPaybarPersonalFieldset-row">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.school.about') }
          </TextNode>
          <FormTextarea
            className="newPaybarPersonalFieldset-input"
            name="about"
            required
            onInput={ this.onInput }
            onValidityChange={ this.onInputValidityChange }
            initialValue={ this.rememberedFields.about }
          />
        </label>

        <label className="newPaybarPersonalFieldset-row has__caption">
          <TextNode className="newPaybarPersonalFieldset-label">
            { I18n.t('newPaybar.school.portfolio') }
          </TextNode>
          <div className="newPaybarPersonalFieldset-inputWithCaption">
            <FormInput className="newPaybarPersonalFieldset-input"
              name="portfolio"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.portfolio }
              ref={ ref => this.fieldRefs.portfolio = ref } />
            <Caption html={ this.portfolioCaption }></Caption>
          </div>
        </label>
      </div>
    )
  }
}

const mapDispatchToProps = {
  setFormValidity,
  addDirtyForm,
  removeDirtyForm,
  setPaymentType,
  setEnrollment,
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    userName: newPaybar.userName,
    userCity: newPaybar.userCity,
    userProfession: newPaybar.userProfession,
    userTel: newPaybar.userTel,
    userTelegram: newPaybar.userTelegram,
    isVisible: newPaybar.isVisible,
    isPreorder: newPaybar.isPreorder,
    paymentType: newPaybar.paymentType,
    enrollment: newPaybar.enrollment,
    prices: newPaybar.prices,
    subscription: newPaybar.subscription,
    isAgreedToTerms: newPaybar.isAgreedToTerms,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPersonalFieldset)
