export function hasCourseStarted(product, startsAt) {
  const isCourse = product === 'course'

  return isCourse && new Date(startsAt) < new Date()
}

export function hasSelfSubscription(subscription) {
  return subscription.subscription_type === 'self'
}

export function hasSelfSubscriptionFromCompany(subscription) {
  return subscription.subscription_type === 'employee'
}
