module.exports = {
  schoolEnrollSoonSpacer: {
    title: {
      designers: 'Скоро набор<br class="device mobile"> в Школу дизайнеров',
      editors: 'Скоро набор<br class="device mobile"> в Школу редакторов',
      managers: 'Скоро набор<br class="device mobile"> в Школу руководителей',
    },
    text: 'Оставьте почту, и мы напишем вам, когда откроется набор.',
  },
  schoolEnrollOpenSpacer: {
    title: {
      designers: 'Новый набор<br class="device mobile"> в Школу дизайнеров',
      editors: 'Новый набор<br class="device mobile"> в Школу редакторов',
      managers: 'Новый набор<br class="device mobile"> в Школу руководителей',
    },
    text: 'Десять бесплатных мест для лучших студентов.',
  },
  schoolEnrollDeadlineScoreSpacer: {
    title: {
      designers: 'Идёт набор<br class="device mobile"> в Школу дизайнеров',
      editors: 'Идёт набор<br class="device mobile"> в Школу редакторов',
      managers: 'Идёт набор<br class="device mobile"> в Школу руководителей',
    },
    text: 'Раньше сдадите — выше шанс на бесплатное место.',
  },
  schoolEnrollEndingSpacer: {
    title: {
      designers: 'Конец набора<br class="device mobile"> в Школу дизайнеров',
      editors: 'Конец набора<br class="device mobile"> в Школу редакторов',
      managers: 'Конец набора<br class="device mobile"> в Школу руководителей',
    },
    text: 'Раньше учёба — ниже стоимость и выше шанс на бесплатное место.',
  },
  schoolEnrollEndedSpacer: {
    title: {
      designers: 'Набор в Школу<br class="device mobile"> дизайнеров закрыт',
      editors: 'Набор в Школу<br class="device mobile"> редакторов закрыт',
      managers: 'Набор в Школу<br class="device mobile"> руководителей закрыт',
      default: 'Итоги набора в Школу бюро',
    },
    text: '<b class="device laptop desktop">Опоздавшим:</b><span class="device mobile">Опоздавшим:</span> оставьте почту, и мы напишем, если будут места.',
    textDefault: '<b class="device laptop desktop">Опоздавшим:</b><span class="device mobile">Опоздавшим:</span> оставьте почту, и мы напишем, если будут свободные места.',
  },
  schoolEnrollMarksSpacer: {
    title: {
      designers: '<span class="device mobile laptop">Итоги набора <br class="device mobile">в Школу дизайнеров</span><span class="device desktop">Оценки и результаты набора Школы дизайнеров</span>',
      editors: '<span class="device mobile laptop">Итоги набора <br class="device mobile">в Школу редакторов</span><span class="device desktop">Оценки и результаты набора Школы редакторов</span>',
      managers: '<span class="device mobile laptop">Итоги набора <br class="device mobile">в Школу руководителей</span><span class="device desktop">Оценки и результаты набора Школы руководителей</span>',
    },
    text: 'Оставьте почту, и мы напишем вам, когда откроется набор.',
  },
  schoolStartedSpacer: {
    title: {
      designers: 'Старт занятий <span class="device desktop">на первой ступени Школы</span><br class="device mobile"><span class="device mobile laptop">в Школе</span> дизайнеров',
      editors: 'Старт занятий <span class="device desktop">на первой ступени Школы</span><br class="device mobile"><span class="device mobile laptop">в Школе</span> редакторов',
      managers: 'Старт занятий <span class="device desktop">на первой ступени Школы</span><br class="device mobile"><span class="device mobile laptop">в Школе</span> руководителей',
    },
    text: 'Оставьте почту, и мы напишем вам, когда откроется набор.',
  },
  schoolPrepsDeadlineScoreSpacer: {
    title: 'Подготовительные курсы',
    text: 'Школы бюро',
  },
  schoolPrepsDeadlineDaysSpacer: {
    title: 'Идёт запись',
    titleMobile: 'Подготовительные курсы',
    text: 'Заработанный балл будет действовать в ближайшем потоке школы. Подготовительные курсы',
    textMobile: 'Школы бюро',
  },
  schoolGraduatedSpacer: {
    caption: 'Дипломы выпускников Школы бюро',
  },
}
