import SubscriptionMark from './subscriptionMark'

const ACCESSIBLE_PRODUCT_IDS_API = '/api/system/books/accessible_products/'

if (/^\/books(\/|$)|\/bookshelf-business/.test(window.location.pathname)) {
  fetch(ACCESSIBLE_PRODUCT_IDS_API, { credentials: 'include' })
    .then(res => res.json())
    .then(products => {
      if (products.length) $('.product-menu-item.is__my-books').removeClass('is__hidden')

      products.forEach((subscription) => {
        $(`.thumbnail[href="/projects/${subscription.product_id}/"]`).each(function() {
          $(this).reactify(SubscriptionMark, subscription)
        })
      })
    })
    .catch(console.error.bind(console))
}
