const deepCopy = (obj) => JSON.parse(JSON.stringify(obj || {}))

const cardIframeUrl = 'https://sandbox2.payture.com/vwapi/Add?SessionId=51777f8a-0d0f-485e-9279-c33466d08b5d'
const accessToken = '123qwe'

const userEmail = 'me@stark.com'
const presenteeEmail = 'pepper@stark.com'

const isVisible = true
const isPreorder = true
const cannotRead = true
const hasSubscribedSelf = true

const today = new Date()
const couponValidUntil = new Date(`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate() + 1} 23:59:59 +0300`)

const prepValidUntil = new Date()
prepValidUntil.setMonth(prepValidUntil.getMonth() + 8)
prepValidUntil.setHours(0, 0, 0)

const nextDeadline = new Date()
nextDeadline.setDate(nextDeadline.getDate() + 7)
nextDeadline.setHours(0, 0, 0)

const cancelledSubscriptionValidUntil = new Date()
cancelledSubscriptionValidUntil.setDate(cancelledSubscriptionValidUntil.getDate() + 3)

const { productSubscription = {} } = window
const existingSubscription = productSubscription.subscription || {}
const existingPrices = productSubscription.prices || {}

const initialSubscription = {
  product_id: existingSubscription.product_id,
  product: existingSubscription.product,
}
const giftSubscription = {
  subscription_type: 'gift',
  valid_until: '2020-01-08T04:00:00.000Z',
  next_payment: '2020-01-09T04:00:00.000Z',
}
const subscription = {
  ...(existingSubscription || {}),
  id: 123,
  ...{
    subscription_type: 'self',
    next_payment: Date.now(),
    valid_until: Date.now(),
    renewal_price: 50,
    renewal_price_in_local_currency: 50,
  },
}

const subscriptionCancelled = {
  product: subscription.product,
  product_id: subscription.product_id,
  status: 'cancelled',
  retention_token_code: 'cc43a6b45fc133ddb74e5abf47bbe09e8cc7077278bfe4cb93969c946b1c7ea20624bdab3d3d5e69',
  retention_token_valid_until: cancelledSubscriptionValidUntil,
}

const preorderSubscription = {
  ...subscription,
  next_payment: null,
  renewal_price: null,
}

const prepSubscription = {
  id: existingSubscription.product_id,
  valid_until: prepValidUntil,
  prepEnrollment: {
    status: null,
    next_deadline_at: nextDeadline,
  },
}

const schoolSubscription = {
  id: existingSubscription.product_id,
  validUntil: prepValidUntil,
  schoolEnrollment: {
    status: null,
    next_deadline_at: nextDeadline,
  },
  paymentType: 'monthly',
  nextPayment: Date.now(),
  renewalPrice: 13750,
}

const companySubscription = {
  ...subscription,
  subscription_type: 'company',
  company: 'Абвгдейка',
  employee_emails: [
    'ilyabirman@ilyabirman.ru',
    'nozikm@artgorbunov.ru',
    'yudmila.u@office.hoster.kz',
    'juliakirillova@artgorbunov.ru',
    'artgorbunov@artgorbunov.ru',
  ],
  max_employees: 5,
  renewal_price: 5000,
}

const user = {
  id: 31288,
  name: 'Тони Старк',
  firstName: 'Тони',
  lastName: 'Старк',
  email: userEmail,
  cardNumber: '4111···1112',
  city: 'Нью-Йорк',
  profession: 'Гений, миллиардер, плейбой, филантроп',
  jobPlace: 'Старк Индастриз',
  tel: '100500',
  telegram: '@tony',
}

const prices = {
  default: 660,
  previous_default: 660,
  one_time: 1980,
  previous_one_time: 1980,
  subscription: 100,
  is_applicable_for_discount: false,
  coupon_valid_until: null,
  previousDefault: 660,
  oneTime: 1980,
  previousOneTime: 1980,
  isApplicableForDiscount: false,
  isApplicableForReaderDiscount: null,
  isCouponApplied: null,
  couponValidUntil: null,
  coupon: 'new_paybar_purchase_upgrade_1',
}

const schoolPricesWeek1 = {
  oneTime: 45000,
  nextOneTime: 45500,
  max: 59500,
  monthly: {
    oneTime: 13750,
    nextOneTime: 13875,
    max: 14875,
  },
  endsAt: '2019-14-11T00:00:00.000+03:00',
  willChangeAt: '2020-02-10T00:00:01.000+03:00',
}

const schoolPricesWeek7 = {
  ...schoolPricesWeek1,
  oneTime: 48000,
  nextOneTime: 48500,
  previousOneTime: 47500,
  monthly: {
    ...schoolPricesWeek1.monthly,
    previousOneTime: 14375,
  },
  endsAt: '2019-14-01T00:00:00.000+03:00',
}

const specialOffer1 = {
  oneTime: 660,
  ownedBooks: [
    { key: 'typography', title: 'Типографика и вёрстка' },
  ],
}

const specialOffer2 = {
  oneTime: 330,
  ownedBooks: [
    { key: 'typography', title: 'Типографика и вёрстка' },
    { key: 'ui', title: 'Пользовательский интерфейс' },
  ],
}

const specialOffer3 = {
  oneTime: 110,
  ownedBooks: [
    { key: 'howtowritethat', title: 'Как написать', isAppendix: true },
    { key: 'typography', title: 'Типографика и вёрстка' },
    { key: 'ui', title: 'Пользовательский интерфейс' },
  ],
}

const specialOfferMore = {
  oneTime: 110,
  ownedBooks: [
    { key: 'typography', title: 'Типографика и вёрстка' },
    { key: 'ui', title: 'Пользовательский интерфейс' },
    { key: 'text', title: 'Информационный стиль' },
    { key: 'howtowritethat', title: 'Как написать', isAppendix: true },
  ],
}

const specialOfferEditor = {
  oneTime: 1190,
  ownedBooks: [
    { key: 'text', title: 'Информационный стиль' },
    { key: 'howtowritethat', title: 'Как написать', isAppendix: true },
  ],
}

const specialOfferDewdeweda = {
  oneTime: 1190,
  ownedBooks: [
    { key: 'dewdeweda', title: 'Дюдюведа‑дюдюуи', isAppendix: true },
  ],
}

const specialOfferAll = {
  oneTime: 3990,
  ownedBooks: [
    { key: 'typography', title: 'Типографика и вёрстка' },
    { key: 'ui', title: 'Пользовательский интерфейс' },
    { key: 'text', title: 'Информационный стиль' },
    { key: 'howtowritethat', title: 'Как написать', isAppendix: true },
  ],
}

const specialOfferAllBookshelves = {
  ...specialOfferAll,
  ownedBookshelves: [
    { key: 'levelone', title: 'Быстрый старт в искусстве' },
  ],
}

const specialOfferAllBookshelvesOnly = {
  ...specialOfferAllBookshelves,
  ownedBooks: [],
}

const specialOfferHasOneLecture = {
  oneTime: 9900,
  ownedLectures: [
    { key: 'typography', title: 'Типографика и вёрстка', author: 'Михаил Нозик', authorInstrumentalCase: 'Михаилом Нозиком' },
  ],
  ownedCollectures: [],
}

const specialOfferHasManyLectures = {
  oneTime: 19900,
  ownedLectures: [
    { key: 'typography', title: 'Типографика и вёрстка', author: 'Михаил Нозик', authorInstrumentalCase: 'Михаилом Нозиком' },
    { key: 'ui', title: 'Пользовательский интерфейс и представление информации', author: 'Илья Бирман', authorInstrumentalCase: 'Ильёй Бирманом' },
  ],
  ownedCollectures: [],
}

const specialOfferHasOneCollecture = {
  oneTime: 19900,
  ownedLectures: [],
  ownedCollectures: [
    { key: 'text', title: 'Для редактора' },
  ],
}

const specialOfferHasManyCollectures = {
  oneTime: 29900,
  ownedLectures: [],
  ownedCollectures: [
    { key: 'text', title: 'Для редактора' },
    { key: 'fff', title: 'Для руководителей' },
  ],
}

const specialOfferHasAll = {
  oneTime: 59900,
  ownedLectures: [
    { key: 'typography', title: 'Типографика и вёрстка', author: 'Михаил Нозик', authorInstrumentalCase: 'Михаилом Нозиком' },
    { key: 'ui', title: 'Пользовательский интерфейс и представление информации', author: 'Илья Бирман', authorInstrumentalCase: 'Ильёй Бирманом' },
  ],
  ownedCollectures: [
    { key: 'text', title: 'Для редактора' },
  ],
}

const upgradeSet = {
  user,
  userEmail,
  type: 'upgrade',
  accessToken,
  cardIframeUrl,
  screen: 'purchase',
  isVisible,
  prices: {
    ...prices,
    specialOffer: specialOffer1,
    one_time: 2880,
    oneTime: 2880,
    renewalPeriod: 12,
    subscription: 1200,
  },
}

const upgradeSetAll = {
  ...upgradeSet,
  prices: {
    ...upgradeSet.prices,
    oneTime: 6990,
    previousOneTime: 6990,
    subscription: 4990,
  },
}

const userNoName = {
  ...user,
  name: '',
  firstName: '',
  lastName: '',
}

const userNoCard = {
  ...user,
  cardNumber: '',
}

const userNoNameNoCard = {
  ...userNoName,
  cardNumber: '',
}


const INITIAL_SCREENS = {
  new_paybar_initial: {
    user: {},
    screen: 'initial',
    isVisible,
    subscription: initialSubscription,
  },

  new_paybar_initial_preorder: {
    user: {},
    screen: 'initial',
    isVisible,
    isPreorder,
    subscription: initialSubscription,
  },

  new_paybar_initial_preorder_cannotread: {
    user: {},
    screen: 'initial',
    isVisible,
    isPreorder,
    cannotRead,
    subscription: initialSubscription,
  },

  new_paybar_initial_school_preorder: {
    user: {},
    screen: 'initial',
    isVisible,
    isPreorder,
    subscription: initialSubscription,
    prices: schoolPricesWeek1,
  },
}

const PURCHASE_SCREENS = {
  new_paybar_purchase: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
  },

  new_paybar_purchase_annual: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    prices: {
      ...existingPrices,
      default: 1650,
      oneTime: 1650,
      subscription: 600,
      renewalPeriod: 12,
    },
  },

  new_paybar_purchase_discount: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    prices: {
      ...existingPrices,
      default: 1440,
      previousDefault: 1650,
      oneTime: 1440,
      previousOneTime: 1650,
      subscription: 600,
      renewalPeriod: 12,
      isApplicableForDiscount: true,
      isApplicableForReaderDiscount: true,
    },
  },

  new_paybar_purchase_preorder: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    isPreorder,
  },

  new_paybar_purchase_preorder_cannotread: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    isPreorder,
    cannotRead,
  },

  new_paybar_purchase_upgrade_1: { ...upgradeSet },

  new_paybar_purchase_upgrade_2: {
    ...upgradeSet,
    prices: {
      ...upgradeSet.prices,
      specialOffer: specialOffer2,
    },
  },

  new_paybar_purchase_upgrade_3: {
    ...upgradeSet,
    prices: {
      ...upgradeSet.prices,
      specialOffer: specialOffer3,
    },
  },

  new_paybar_purchase_upgrade_more: {
    ...upgradeSet,
    prices: {
      ...upgradeSet.prices,
      specialOffer: specialOfferMore,
    },
  },

  new_paybar_purchase_upgrade_editor: {
    ...upgradeSet,
    prices: {
      ...upgradeSet.prices,
      specialOffer: specialOfferEditor,
    },
  },

  new_paybar_purchase_upgrade_dewdeweda: {
    ...upgradeSet,
    prices: {
      ...upgradeSet.prices,
      specialOffer: specialOfferDewdeweda,
    },
  },

  new_paybar_purchase_upgrade_all: {
    ...upgradeSetAll,
    prices: {
      ...upgradeSetAll.prices,
      specialOffer: specialOfferAll,
    },
  },

  new_paybar_purchase_upgrade_all_bookshelves: {
    ...upgradeSetAll,
    prices: {
      ...upgradeSetAll.prices,
      specialOffer: specialOfferAllBookshelves,
    },
  },

  new_paybar_purchase_upgrade_all_bookshelves_only: {
    ...upgradeSetAll,
    prices: {
      ...upgradeSetAll.prices,
      specialOffer: specialOfferAllBookshelvesOnly,
    },
  },

  new_paybar_purchase_school_preorder: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    isPreorder,
    prices: schoolPricesWeek1,
  },

  new_paybar_purchase_school_preorder_week_7: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    isPreorder,
    prices: schoolPricesWeek7,
  },

  new_paybar_purchase_prep_coupon: {
    accessToken,
    cardIframeUrl,
    user: {},
    userEmail,
    screen: 'purchase',
    isVisible,
    prices: {
      ...existingPrices,
      isCouponApplied: true,
      couponValidUntil,
      oneTime: 4000,
      coupon: 'live_coupon',
    },
  },
}

const SUCCESS_SCREENS = {
  new_paybar_success: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
  },

  new_paybar_success_upgrade: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOffer1,
    },
  },

  new_paybar_success_upgrade_all: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOfferAll,
    },
  },

  new_paybar_success_collecture_upgrade_from_one_lecture: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOfferHasOneLecture,
    },
  },

  new_paybar_success_collecture_upgrade_from_many_lectures: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOfferHasManyLectures,
    },
  },

  new_paybar_success_collecture_upgrade_from_one_collecture: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOfferHasOneCollecture,
    },
  },

  new_paybar_success_collecture_upgrade_from_many_collectures: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOfferHasManyCollectures,
    },
  },

  new_paybar_success_collecture_upgrade_from_all: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    prices: {
      ...upgradeSet.prices,
      specialOffer: null,
      previousSpecialOffer: specialOfferHasAll,
    },
  },

  new_paybar_success_gift: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    type: 'gift',
    isVisible,
    presenteeEmail,
  },

  new_paybar_success_preorder: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    isPreorder,
  },

  new_paybar_success_preorder_gift: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    type: 'gift',
    isVisible,
    presenteeEmail,
    isPreorder,
  },

  new_paybar_success_preorder_cannotread: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    isVisible,
    isPreorder,
    cannotRead,
  },

  new_paybar_success_preorder_shelf: {
    user: {},
    userEmail,
    screen: 'success',
    isVisible,
    subscription: preorderSubscription,
  },

  new_paybar_success_prep: {
    user: {},
    userEmail,
    screen: 'success',
    isVisible,
    subscription: {
      ...subscription,
      ...prepSubscription,
    },
  },

  new_paybar_success_school_preorder: {
    user: {},
    userEmail,
    screen: 'success',
    isVisible,
    isPreorder,
    subscription: {
      ...subscription,
      ...schoolSubscription,
    },
  },
  new_paybar_success_course_paid_by_invoice_self: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    type: 'company',
    companyPaymentType: 'invoice',
    participantType: 'self',
    isVisible,
    newSubscriptionCompany: { email: 'company@stark.com' },
  },
  new_paybar_success_course_paid_by_invoice_other: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    type: 'company',
    companyPaymentType: 'invoice',
    isVisible,
    presenteeEmail,
    participantType: 'other',
    newSubscriptionCompany: { email: 'company@stark.com' },
  },
  new_paybar_success_course_paid_by_card_self: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    type: 'company',
    companyPaymentType: 'card',
    participantType: 'self',
    isVisible,
  },
  new_paybar_success_course_paid_by_card_other: {
    user: {},
    subscription,
    userEmail,
    screen: 'success',
    type: 'company',
    companyPaymentType: 'card',
    isVisible,
    presenteeEmail,
    participantType: 'other',
  },
}

const SUBSCRIPTION_SCREENS = {
  new_paybar_subscription: {
    user: {},
    screen: 'subscription',
    isVisible,
    userEmail,
    noticeType: 'emailSent',
    subscription,
    hasSubscribedSelf,
  },

  new_paybar_subscription_preorder: {
    user: {},
    screen: 'subscription',
    isVisible,
    userEmail,
    noticeType: 'emailSent',
    subscription: preorderSubscription,
    hasSubscribedSelf,
    isPreorder,
  },

  new_paybar_subscription_preorder_cannotread: {
    user: {},
    screen: 'subscription',
    isVisible,
    userEmail,
    noticeType: 'emailSent',
    subscription,
    hasSubscribedSelf,
    isPreorder,
    cannotRead,
  },

  new_paybar_subscription_preorder_shelf: {
    user: {},
    screen: 'subscription',
    isVisible,
    userEmail,
    subscription: preorderSubscription,
    hasSubscribedSelf,
    isPreorder,
  },

  new_paybar_subscription_presentee: {
    ...user,
    screen: 'subscription',
    subscription: {
      ...subscription,
      ...giftSubscription,
    },
    isVisible,
  },

  new_paybar_subscription_prep: {
    ...user,
    screen: 'subscription',
    subscription: {
      ...subscription,
      ...prepSubscription,
    },
    isVisible,
  },

  new_paybar_subscription_prep_studying: {
    ...user,
    screen: 'subscription',
    subscription: {
      ...subscription,
      ...prepSubscription,
      prepEnrollment: {
        ...prepSubscription.prepEnrollment,
        status: 'active',
      },
    },
    isVisible,
  },

  new_paybar_subscription_school_preorder: {
    ...user,
    screen: 'subscription',
    subscription: {
      ...subscription,
      ...schoolSubscription,
    },
    userEmail,
    noticeType: 'emailSent',
    isPreorder,
    isVisible,
    hasSubscribedSelf,
  },

  new_paybar_subscription_school_finished: {
    ...user,
    screen: 'subscription',
    subscription: {
      ...subscription,
      ...schoolSubscription,
      schoolEnrollment: { status: 'finished' },
    },
    isVisible,
  },

  new_paybar_subscription_company: {
    ...user,
    screen: 'subscription',
    type: 'company',
    subscription: companySubscription,
    hasSubscribedSelf,
    isVisible,
  },

  new_paybar_subscription_cancelled: {
    user: {},
    screen: 'subscription',
    isVisible,
    userEmail,
    noticeType: 'emailSent',
    subscription: subscriptionCancelled,
    lastSubscription: subscription,
    hasSubscribedSelf,
  },

  new_paybar_subscription_cancelled_preorder: {
    user: {},
    screen: 'subscription',
    isVisible,
    userEmail,
    noticeType: 'emailSent',
    subscription: subscriptionCancelled,
    lastSubscription: subscription,
    hasSubscribedSelf,
    isPreorder,
  },
}

const mergeWithUser = (data, suffix = 'user', userData = user) => (result, key) => {
  const notice = data[key].noticeType
  const isEmailSentNotice = notice === 'emailSent'
  const noticeType = isEmailSentNotice ? null : notice

  result[`${ key }_${ suffix }`] = {
    ...data[key],
    ...{ user: userData },
    noticeType,
  }

  return result
}

const PURCHASE_USER_SCREENS = Object.keys(PURCHASE_SCREENS)
  .reduce(mergeWithUser(PURCHASE_SCREENS), {})

const PURCHASE_USER_NONAME_SCREENS = Object.keys(PURCHASE_SCREENS)
  .reduce(mergeWithUser(PURCHASE_SCREENS, 'user_noname', userNoName), {})

const PURCHASE_USER_NOCARD_SCREENS = Object.keys(PURCHASE_SCREENS)
  .reduce(mergeWithUser(PURCHASE_SCREENS, 'user_nocard', userNoCard), {})

const PURCHASE_USER_NONAME_NOCARD_SCREENS = Object.keys(PURCHASE_SCREENS)
  .reduce(mergeWithUser(PURCHASE_SCREENS, 'user_noname_nocard', userNoNameNoCard), {})

const SUCCESS_USER_SCREENS = Object.keys(SUCCESS_SCREENS)
  .reduce(mergeWithUser(SUCCESS_SCREENS), {})

const SUBSCRIPTION_USER_SCREENS = Object.keys(SUBSCRIPTION_SCREENS)
  .reduce(mergeWithUser(SUBSCRIPTION_SCREENS), {})

const VARIANTS = Object.assign(
  {},
  INITIAL_SCREENS,
  PURCHASE_SCREENS,
  PURCHASE_USER_SCREENS,
  PURCHASE_USER_NONAME_SCREENS,
  PURCHASE_USER_NOCARD_SCREENS,
  PURCHASE_USER_NONAME_NOCARD_SCREENS,
  SUCCESS_SCREENS,
  SUCCESS_USER_SCREENS,
  SUBSCRIPTION_SCREENS,
  SUBSCRIPTION_USER_SCREENS,
)

const shouldInstallStubs = () => /localhost|\.artgorbunov|\.bureau/.test(window.location.hostname)

const install = () => {
  if (!shouldInstallStubs()) return productSubscription

  const search = window.location.search.replace('?', '')
  const firstSearchParam = search.split('&')[0]

  const currentVariant = Object.keys(VARIANTS).find(variant => variant === firstSearchParam)
  const stub = deepCopy(VARIANTS[currentVariant])

  return { ...productSubscription, ...stub }
}

module.exports = { install }
