import { Component } from 'preact'
import cx from 'classnames'

import { Heading3 } from '../../textNodes'

export default class NewPaybarPaymentTypeSwitch extends Component {
  render() {
    const isCard = this.props.paymentType === 'card'

    const switcherItems = [
      {
        title: I18n.t('newPaybar.course.paymentTypeSwitch.card'),
        isActive: isCard,
        onClick: this.props.onSetCard,
      },
      {
        title: I18n.t('newPaybar.course.paymentTypeSwitch.invoice'),
        isActive: !isCard,
        onClick: this.props.onSetInvoice,
      },
    ]

    return (
      <div className="newPaybarSwitch">
        <Heading3 className="newPaybarSwitch-heading">
          { I18n.t('newPaybar.course.paymentType') }
        </Heading3>
        <div className="newPaybarSwitch-selector">
          { switcherItems.map(({ title: __html, isActive, isDisabled, onClick }) => (
          <span
            className={ cx('newPaybarSwitch-selectorItem', { is__active: isActive, is__disabled: isDisabled }) }
            onClick={ onClick }
            dangerouslySetInnerHTML={{ __html }} />
          ))}
        </div>
      </div>
    )
  }
}
