
const SchoolSpacerTitle = (props) => {
  const titleHTML = { __html: props.title }

  return (
    <div className="schoolSpacerTitle-wrapper">
      <a
        href={ props.href }
        className="schoolSpacerTitle doubleHover is__noUnderline"
        dangerouslySetInnerHTML={ titleHTML }
      />
    </div>
  )
}

export default SchoolSpacerTitle
