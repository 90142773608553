import { Component } from 'preact'
import cx from 'classnames'
import StickyButtonCountdown from './stickyButtonCountdown'

const MIN_DESKTOP_WIDTH = 961
const AVG_BUTTON_WIDTH = 140
const REGULAR_TOP_OFFSET = 27
const READY_CLASS = 'is__ready'

class StickyButton extends Component {
  constructor(props) {
    super(props)

    this.calculateShaftPosition = this.calculateShaftPosition.bind(this)
    this.calculateShaftAndButtons = this.calculateShaftAndButtons.bind(this)
    this.defaultOnClick = this.defaultOnClick.bind(this)
    this.showButtonForFirstTime = this.showButtonForFirstTime.bind(this)
  }

  componentDidMount() {
    this.$body = $('body')

    this.$topEl = this.$topEl || $('.js__stickyButtonTopEdge, .js__stickyOrderButtonTopEdge')
    if (!this.$topEl.length) this.$topEl = this.$body

    this.$bottomEl = $('.js__stickyButtonBottomEdge, .js__stickyOrderButtonBottomEdge')
    if (!this.$bottomEl.length) this.$bottomEl = this.$body

    this.$mobileTopEl = $('.js__mobileStickyButtonTopEdge, .js__mobileStickyOrderButtonTopEdge')
    if (!this.$mobileTopEl.length) this.$mobileTopEl = this.$topEl

    this.$stickyButtonDouble = $('.js__stickyButtonDouble')

    this.setupButtons()

    $(document)
      .on('appReady appResize', this.calculateShaftAndButtons)
      .on('appReady', () => { setTimeout(this.showButtonForFirstTime, 1000) })
      .on('appResize', () => { setTimeout(this.calculateShaftAndButtons, 1000) })
  }

  componentWillUpdate() {
    this.$stickyButtonDouble.html(this.text)
  }

  componentDidUpdate(prevProps) {
    const isContentChanged = prevProps.text !== this.props.text ||
      prevProps.countdown !== this.props.countdown

    if (isContentChanged) {
      this.resetButtons()

      // HACK: стили должны успеть сброситься до пересчёта размеров кнопок
      setTimeout(this.calculateShaftAndButtons.bind(this), 250)
    }
  }

  showButtonForFirstTime() {
    this.calculateShaftAndButtons()
    this.$shaft.classList.add(READY_CLASS)
  }

  resetButtons() {
    this.setState({
      mainButtonWidth: 'none',
      extraButtonWidth: 'none',
      isButtonFlexible: false,
      isExtraButtonCompact: false,
      isSetupComplete: false,
    })
  }

  setupButtons() {
    if (!this.needsToHandleExtraButton) return

    this.setState({
      mainButtonWidth: this.$el.offsetWidth,
      mainButtonMargin: parseFloat(window.getComputedStyle(this.$el).marginRight),
      extraButtonWidth: this.$extraButtonWrap.offsetWidth,
      isButtonFlexible: true,
      isExtraButtonCompact: false,
      isSetupComplete: true,
    })
  }

  calculateButtons() {
    if (!this.needsToHandleExtraButton) return

    if (!this.state.isSetupComplete) {
      this.setupButtons()
    }

    const isLongButton = this.state.mainButtonWidth > (AVG_BUTTON_WIDTH * 1.5)
    const mainButtonMaxWidth = isLongButton ? AVG_BUTTON_WIDTH : this.state.mainButtonWidth

    const buttonsTotalWidth = mainButtonMaxWidth +
      this.state.mainButtonMargin +
      this.state.extraButtonWidth


    this.setState({ isExtraButtonCompact: buttonsTotalWidth >= this.$inner.offsetWidth })
  }

  calculateShaftPosition() {
    this.buttonSize = this.$el.offsetHeight

    this.setState({
      shaftTopOffset: this.getTopShaftOffset(),
      shaftBottomOffset: this.getBottomShaftOffset(),
    })

    this.hackSafariRenderingIssue()
  }

  updateDoubleButtonWidth() {
    this.$stickyButtonDouble.css({ width: this.$inner.offsetWidth })
  }

  calculateShaftAndButtons() {
    this.calculateButtons()
    this.updateDoubleButtonWidth()
    this.calculateShaftPosition()
  }

  getTopShaftOffset() {
    if (this.$topEl.is('.is__regular')) { // FIXME: soon should be default behaviour
      if (this.isMobile) {
        return REGULAR_TOP_OFFSET + this.$mobileTopEl.offset().top + this.$mobileTopEl.height()
      }

      return REGULAR_TOP_OFFSET + this.$topEl.offset().top + this.$topEl.height()
    }


    if (this.isMobile) {
      return this.$mobileTopEl.offset().top + this.$mobileTopEl.height() + this.buttonSize
    }

    return this.$topEl.offset().top + this.$topEl.height()
  }

  getBottomShaftOffset() {
    const bottomElOffset = this.$bottomEl.offset().top

    if (this.isMobile) return bottomElOffset + this.buttonSize

    return bottomElOffset + this.$bottomEl.height()
  }

  get mainButtonClassNames() {
    return cx(
      'stickyButton',
      `is__${this.props.type}`,
      {
        is__flexible: this.state.isButtonFlexible,
        is__pulsating: !!this.props.countdown,
      }
    )
  }

  get extraButtonClassNames() {
    return cx(
      'stickyButtonShaft-extraButtonWrap',
      { is__flexible: this.state.isButtonFlexible },
      { is__compact: this.state.isExtraButtonCompact }
    )
  }

  get text() {
    return this.props.text
  }

  get shaftStyle() {
    return {
      top: `${ this.state.shaftTopOffset }px`,
      height: `${ this.state.shaftBottomOffset - this.state.shaftTopOffset }px`,
    }
  }

  get isMobile() {
    // FIXME: window.innerWidth почему-то меняется во время загрузки страницы, пока заменил на $
    return this.$body.width() < MIN_DESKTOP_WIDTH
  }

  get needsToHandleExtraButton() {
    return this.$extraButtonWrap && !this.isMobile
  }

  defaultOnClick() {
    if (this.props.href) window.location = this.props.href
  }

  hackSafariRenderingIssue() {
    this.setState({ style: { transform: 'scale(1)' } })
    setTimeout(() => this.setState({ style: {} }), 100)
  }

  render() {
    const hasChildren = this.props.children.filter(el => el).length > 0
    const hasCountdown = !!this.props.countdown

    return (
      <div className="stickyButtonShaft" style={ this.shaftStyle } ref={ $el => this.$shaft = $el }>
        <div className="stickyButtonShaft-inner"
          ref={ $el => this.$inner = $el }
        >

          <button className={ this.mainButtonClassNames }
            onClick={ this.props.onClick || this.defaultOnClick }
            dangerouslySetInnerHTML={ hasCountdown ? false : { __html: this.text } }
            ref={ $el => this.$el = $el }
            data-goal={ this.props.goal || this.goal }
            style={ `max-width: ${this.state.mainButtonWidth}px;` }
          >
            { hasCountdown && <StickyButtonCountdown to={ this.props.countdown }/>}
          </button>

          { hasChildren &&
            <div className={ this.extraButtonClassNames }
              ref={ $el => this.$extraButtonWrap = $el }
              style={ `max-width: ${this.state.extraButtonWidth}px;` }
            >
              { this.props.children }
            </div>
          }
        </div>
      </div>
    )
  }
}

module.exports = StickyButton
