const DEFAULT_TABS = {
  self: 'Себе',
  gift: 'В подарок',
}

const DEFAULT_GIFT_MESSAGES = {
  withDiscount: {
    subscription_1: 'Без дополнительных списаний. Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/мес.</span>',
    subscription_12: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span>',
  },
  withoutDiscount: {
    subscription_1: 'Без дополнительных списаний. Получатель подарка сможет привязать карту и продлить подписку по тарифу <span class="nowrap">%{price} ₽/мес.</span>',
    subscription_12: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по тарифу <span class="nowrap">%{price} ₽/год</span>',
  },
}

module.exports = {
  sentence: { lastWordConnector: 'и' },

  dateFormats: {
    dateOnly: '%{day} %{month}',
    dateYearOnly: '%{day} %{month} %{year}',
  },

  site: {
    name: 'Бюро Горбунова',
    logoName: 'Бюро Горбунова',
    footerCopyright: 'Бюро Горбунова',
    host: 'bureau.ru',
  },

  docTypes: {
    lecture: 'Лекция',
    literature: 'Литература',
    task: 'Задание',
    test: 'Тест',
    text: 'Лекция',
  },

  impressions: {
    upvote: 'Полезно',
    downvote: 'Непонятно',
    hint: '<a href="/burosfera/me" class="key-link">Войдите в Бюросферу</a>, чтобы голосовать',
  },

  likely: {
    telegram: 'Отправить',
    facebook: 'Поделиться',
    vkontakte: 'Поделиться',
    pinterest: 'Запинить',
    twitter: 'Твитнуть',
  },

  project: {
    bucketCaption: 'Ведро',
    stickyButton: 'Стать клиентом',
  },

  recycledLinks: {
    overview: 'Обзор',
    recycled: '🗑 Ведро',
  },

  timeline: {
    start: 'Старт',
    launch: 'Пуск',
    launches: 'Пуски',
    byPlan: ' по плану',
    inFact: 'Фактически',
  },

  comments: {
    menu: {
      tabs: {
        all: 'Все',
        new: 'Неопубликованные',
        sunk: 'Вода',
      },
    },
  },

  defaultTotalTableHeading: 'Итого',

  books: {
    spacerDiscount: {
      text: 'Для тех, кто уже подписан <nobr><a href="/books/" class="doubleHover">на другие книги бюро</a>,</nobr> <nobr>спеццена —</nobr>',
      textMobile: 'Для тех, кто уже подписан <nobr><a href="/books/" class="doubleHover">на другие книги бюро</a></nobr>, <nobr>спеццена за первые</nobr> <nobr>три месяца</nobr> подписки',
      textMobileAnnual: 'Для тех, кто уже подписан <nobr><a href="/books/" class="doubleHover">на другие книги бюро</a></nobr>, <nobr>спеццена за первый год</nobr> подписки',
      monthly: 'За первые<br>три месяца',
      annual: 'За первый год подписки',
    },
    spacerDemo: {
      title: 'Демоглава',
      button: 'Попробовать бесплатно',
    },
    bookSpacer: {
      subscriptionFormCaption: 'Оставьте почту, чтобы узнать о предзаказе.',
      discount: 'скидка',
    },
    bookSystemRequirements: {
      mainWithoutMobile: 'Для чтения нужен компьютер или планшет с интернетом и любым современным браузером',
      mainWithMobile: 'Для чтения нужен компьютер, планшет или телефон с интернетом и любым современным браузером',
      retina: '<span class="bookSystemRequirements-eye">👁</span> Рекомендуем читать на экранах с «ретиной». На обычных тоже работает',
    },
    booksIntro: {
      lead: 'Мы выпускаем  электронные учебники по типографике, интерфейсу, редактуре, управлению проектами и другим дисциплинам. Наши книги прекрасно выглядят и представляют знания в интерактивной форме.',
      manifesto: 'О нашем формате книг<span class="device laptop desktop"> в манифесте</span>',
      aside: 'Почему книги доступны только по подписке, сколько стоит подписка и как её отменить?',
      faq: 'Вопросы и ответы',
      devices: 'Читайте на компьютере и планшете',
      ownedBooks: 'Так на этой странице отмечаются книги, на которые вы подписаны',
      subscriptionFormExplanation: 'Оставьте почту, чтобы узнать о выходе книг и обновлениях других продуктов бюро.',
    },
    priceCaption_1: 'Подписка минимум на три месяца',
    priceCaption_12: 'Подписка минимум на год',
    priceCaptionRenewsAutomatically: 'c автопродлением',
    specialPriceCaptionRenewsAutomatically: 'Подписка c автопродлением',
    discountPriceCaption: '<em>Спеццена</em> для подписчиков на книги бюро',
    discountPriceCaptionPreorder: 'При предзаказе <em>2 месяца в подарок</em>',
    hasMobileVersionSticker: 'С версией для телефона',
    stickerPreorderAndRead: 'Предзакажите и читайте',
    otherBooks: {
      defaultTitle: 'Ещё книги',
      bookCaption: 'Первый год <nobr>%{price} ₽</nobr>, <br class="device laptop desktop">затем <nobr>%{subscriptionPrice} ₽/год</nobr> ',
      bookCaptionWithDiscount: 'Первый год <nobr><s>%{price}</s> %{newPrice} ₽</nobr><span class="device desktop">, <br class="device laptop desktop">затем <nobr>%{subscriptionPrice} ₽/год</nobr></span> ',
      bookshelfCaption: '<b>%{name}</b><br/>Первый год <nobr>%{price} ₽</nobr>, затем <nobr>%{subscriptionPrice} ₽/год</nobr>',
      bookshelfCaptionWithDiscount: '<b>%{name}</b><br/>Первый год <nobr><s>%{price}</s> %{newPrice} ₽</nobr><span class="device desktop">, затем <nobr>%{subscriptionPrice} ₽/год</nobr></span>',
    },
    subscriptionFooter: {
      caption: 'Подписка c автопродлением',
      captionCompact: 'С автопродлением',
      captionPreorder: 'При предзаказе <span class="is__highlighted">2 месяца в подарок.</span> <br/>Подписка c автопродлением',
      shelfTitle: 'Полка «%{productTitle}»',
      bundleTitle: 'Все книги бюро',
      bundleTitleWithBook: 'Эта и все книги бюро',
      subscription: 'Подписка',
      subscriptionForm: {
        title: 'Когда что выйдет',
        placeholder: 'Электронная почта',
        caption: 'Оставьте почту, чтобы узнать о выходе книг и обновлениях других продуктов бюро.',
        captionPreorder: 'Оставьте почту, чтобы узнать о выходе книги и обновлениях других продуктов бюро.',
      },
      subscriptionButton: {
        caption: 'Подписка',
        captionPreorder: 'Предзаказ',
      },
    },
    bookshelf: {
      offerTitle: 'Подписка для новых читателей',
      specialOfferTitle: 'Для подписчиков на %{booksCount} %{pluralizedBookCount}',
      bookshelfAllspecialOfferTitle: 'Для подписчиков на отдельные книги',
      subscriptionFooter: {
        caption: 'С автопродлением',
        captionSpecial: 'Подписка c автопродлением',
        captionUpgrade: 'При переключении <span class="is__highlighted">2 месяца в подарок.</span> <br/>Подписка c автопродлением',
        captionUpgradeSpecial: 'Два месяца <span class="is__highlighted">в подарок.</span> <br/>Подписка c автопродлением',
      },
      requirements: {
        hasMobileVersion: {
          none: 'Для чтения нужен компьютер или планшет с интернетом и любым современным браузером',
          all: 'Для чтения нужен компьютер, планшет или телефон с интернетом и любым современным браузером',
          some: 'Для чтения нужен компьютер или планшет <br class="device desktop laptop" />с интернетом и любым современным браузером.<br class="device desktop laptop" /> У некоторых книг есть версия для телефона',
        },
      },
    },
  },

  subscription: {
    subscription: 'Подписка',
    preorder: 'Предзаказ',
    unitsOneTime: '₽',
    unitsMonthly: '₽/мес.',
    unitsYearly: '₽/год',
    defaultUnits_1: '₽/мес.',
    defaultUnits_1Discount: '₽',
    defaultUnits_7: '₽',
    defaultUnits_12: '₽',
    defaultUnits_14: '₽',
    defaultUnits_12Discount: '₽',
    subscriptionUnits_1: '₽/мес.',
    subscriptionUnits_7: '₽/мес.',
    subscriptionUnits_12: '₽/год',
    subscriptionUnits_14: '₽/год',
    prices: {
      defaultLabel_1: 'В первые три месяца',
      defaultLabel_1Discount: 'За первые три месяца',
      defaultLabel_7: 'За первые 6 месяцев',
      defaultLabel_7Discount: 'За первые 6 месяцев',
      defaultLabel_12: 'Первый год',
      defaultLabel_14: 'Первый год',
      defaultLabel_12Discount: 'За первый год',
      subscriptionLabel_1: 'С четвёртого',
      subscriptionLabel_7: 'С восьмого',
      subscriptionLabel_12: 'Со второго года',
      subscriptionLabel_14: 'Со второго года',
      upgradeLabel: 'Переключение',
      upgradeSubscriptionLabel: 'Со второго года',
      after: 'Затем',
    },
    license: 'Лицензия',
    unsubscription: 'Отмена подписки',
    subscribed: '✓ Вы подписаны',
    read: 'Читать',
    notRenewedAutomatically: 'Подписка не продлевается автоматически.',
    giftPricingInfo: 'Тот, кому вы дарите, сможет привязать свою карту и продлить подписку по сниженному тарифу.',
    noAdditionalCharges: 'У вас не будет дополнительных списаний.',
    preorderFor: 'Предзаказ за',
    buyMoreFor: 'Купить ещё за',
    nextPayment: 'Следующий платёж',
    subscriptionSwitched: 'Подписка переключена',
    subscriptionMarkLabel: {
      default: 'У вас',
      lastDay: 'дня ещё у вас',
      daysLeft: {
        one: 'день ещё у вас',
        few: 'дня ещё у вас',
      },
    },
    retry: '<a href="mailto:%{email}">Напишите нам</a>, если проблема будет повторяться <a href="%{url}" class="button subscriptionRetry-link">Попробовать снова</a>',
    updateCard: 'Обновить или указать другую банковскую карту',
  },

  emailForm: {
    placeholder: 'Электронная почта',
    authenticationEmailSent: 'По адресу <b>%{email}</b> отправлена ссылка на вашу личную страницу',
  },

  newPaybar: {
    emailNotices: {
      default: {
        self: 'Адрес, к которому хотите привязать покупку. Если у вас есть Бюросфера с другими покупками, используйте адрес оттуда<span class="device mobile">:</span>',
        upgrade: 'Адрес, к которому хотите привязать покупку. Если у вас есть Бюросфера с другими покупками, используйте адрес оттуда<span class="device mobile">:</span>',
        gift: 'Адрес того, кто оплатит подарок. Если у вас есть Бюросфера с другими покупками, используйте адрес оттуда<span class="device mobile">:</span>',
        company: 'Адрес, к которому хотите привязать покупку. Если у вас есть Бюросфера с другими покупками, используйте адрес оттуда<span class="device mobile">:</span>',
      },
      bookshelf: {
        hasSubscription: 'У вас уже есть подписка. По адресу <b>%{email}</b> отправлена ссылка для доступа к полке',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для оформления подписки',
      },
      book: {
        hasSubscription: 'У вас уже есть подписка. По адресу <b>%{email}</b> отправлена ссылка для доступа к книге',
        hasSubscriptionCannotRead: 'У вас уже есть подписка. Мы напишем по адресу <b>%{email}</b>, когда книга выйдет',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для оформления подписки',
        hasBureausphereCannotRead: 'По адресу <b>%{email}</b> отправлена ссылка для оформления подписки',
      },
      prep: {
        hasSubscription: 'Вы уже записались на подготовительные курсы. По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для записи на подготовительные курсы',
      },
      school: {
        hasSubscription: 'Вы уже поступили в школу. По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету',
        hasSubscriptionPreorder: 'Вы уже поступили в школу. Мы напишем по адресу <b>%{email}</b>, когда начнутся занятия',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для поступления в школу',
        hasBureauspherePreorder: 'По адресу <b>%{email}</b> отправлена ссылка для поступления в школу',
      },
      lecture: {
        hasSubscription: 'У вас уже есть подписка. По адресу <b>%{email}</b> отправлена ссылка для доступа к лекциям',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для оформления подписки',
      },
      collecture: {
        hasSubscription: 'У вас уже есть подписка. По адресу <b>%{email}</b> отправлена ссылка для доступа к лекциям',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для оформления подписки',
      },
      course: {
        hasSubscription: 'Вы уже записались на курс. По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету',
        hasBureausphere: 'По адресу <b>%{email}</b> отправлена ссылка для записи на курс',
      },
    },
    labels: { fullName: 'Имя и фамилия' },
    priceLabel: {
      bookshelf: {
        gift_1: 'На три месяца',
        gift_12: 'На год',
      },
      book: {
        gift_1: 'На три месяца',
        gift_12: 'На год',
      },
      lecture: {
        self_12: 'На год',
        gift_12: 'На год',
      },
      collecture: {
        self_1: 'На три месяца',
        gift_1: 'На три месяца',
        self_12: 'На год',
        gift_12: 'На год',
      },
    },
    devices: {
      book: {
        default: 'Компьютер<br class="device laptop desktop" /> и планшет',
        hasMobileVersion: 'Компьютер,<br class="device laptop desktop" /> планшет<br /> <span class="is__colored">и телефон</span>',
      },
      bookshelf: {
        hasMobileVersion: {
          all: 'Компьютер,<br class="device laptop desktop" /> планшет<br /> <span class="is__colored">и телефон</span>',
          some: 'Компьютер,<br class="device laptop desktop" /> планшет<br /> <span class="is__colored">и телефон<br class="device laptop desktop" /> для некоторых<br class="device laptop desktop" /> книг</span>',
          none: 'Компьютер<br class="device laptop desktop" /> и планшет',
        },
      },
      lecture: 'Компьютер,<br class="device laptop desktop" /> планшет<br /> и телефон',
    },
    requirements: {
      book: {
        default: 'Для чтения нужен компьютер или планшет с интернетом и любым современным браузером. Рекомендуем читать на экранах с «ретиной»',
        hasMobileVersion: 'Для чтения нужен компьютер, планшет или телефон с интернетом и любым современным браузером. Рекомендуем читать на экранах с «ретиной»',
      },
      bookshelf: {
        default: 'Для чтения нужен компьютер или планшет с интернетом и любым современным браузером. Рекомендуем читать на экранах с «ретиной»',
        hasMobileVersion: 'Для чтения нужен компьютер, планшет или телефон с интернетом и любым современным браузером. Рекомендуем читать на экранах с «ретиной»',
      },
      prep: 'Для прохождения подготовительных курсов нужен компьютер, планшет или телефон с интернетом и любым современным браузером',
      school: 'Для обучения нужен компьютер, <br class="device laptop desktop">планшет или телефон с интернетом <br class="device laptop desktop">и любым современным браузером',
      lecture: 'Для просмотра нужен компьютер, <br class="device laptop desktop">планшет или телефон с интернетом <br class="device laptop desktop">и любым современным браузером',
      collecture: 'Для просмотра нужен компьютер, <br class="device laptop desktop">планшет или телефон с интернетом <br class="device laptop desktop">и любым современным браузером',
    },
    hasMobileVersion: {
      single: 'Теперь у книги «%{bookTitle}» есть версия для телефона',
      many: 'Так отмечаются книги, у которых есть версия для телефона',
    },
    subscriptionStatus: {
      book: '<b>Следующий платёж</b> <br class="device laptop desktop" />%{date} — <span class="nowrap">%{price} ₽</span>',
      bookshelf: '<b>Следующий платёж</b> <br class="device laptop desktop" />%{date} — <span class="nowrap">%{price} ₽</span>',
      school: '<b>Следующий платёж</b> <br class="device laptop desktop" />%{date} — <span class="nowrap">%{price} ₽</span>',
      lecture: '<b>Следующий платёж</b> <br class="device laptop desktop" />%{date} — <span class="nowrap">%{price} ₽</span>',
      collecture: '<b>Следующий платёж</b> <br class="device laptop desktop" />%{date} — <span class="nowrap">%{price} ₽</span>',
      gift: {
        book: 'Подписка действует <br class="device laptop desktop" /> <b>до %{date}</b>',
        bookshelf: 'Подписка действует <br class="device laptop desktop" /> <b>до %{date}</b>',
        lecture: 'Подписка действует до %{date}',
      },
    },
    cardIframe: {
      title: 'Оплата<span class="device desktop laptop"> банковской</span> картой',
      cvvHelp: 'CVC 2 / CVV 2 — три последние цифры на обороте',
      paytureHelp: 'Данные карты будут сохранены на защищённом платёжном сервере Payture. <nobr>Дизайн-бюро</nobr> Артёма Горбунова не хранит данные вашей карточки.',
    },
    anotherEmail: '<span class="is__hiddenWhenCompact">У меня </span><span class="is__capitalizedWhenCompact">другой адрес</span>',
    imNotUserName: 'Я не %{firstName}',
    changeCard: 'Изменить',
    newCardCaption: 'Данные предыдущей карты будут автоматически удалены',
    payerName: 'Имя и фамилия',
    termsCheckbox: {
      school: {
        self: 'Принять <a href="/soviet/20140623/" target="_blank">кодекс бюрошника</a> и <a href="/school/license/" target="_blank">оферту</a>',
        gift: 'Принять <a href="/school/license/" target="_blank">оферту</a>',
      },
      course: {
        self: 'Принять <a href="/courses/license/" target="_blank">оферту</a>',
        gift: 'Принять <a href="/courses/license/" target="_blank">оферту</a>',
        company: 'Принять <a href="/courses/license/" target="_blank">оферту</a>',
      },
    },
    termsCheckboxCaption: {
      school: 'Если не достанется бесплатное место, учиться платно или завершить обучение в соответствии с офертой',
    },
    gift: {
      payerName: 'Ваши имя и фамилия',
      presentee: 'Кому дарите?',
      presenteeName: 'Имя',
      presenteeEmail: 'Эл. почта',
    },
    company: {
      plan: 'Тариф',
      company: 'Название компании',
      emails: 'Эл. адреса сотрудников',
    },
    school: {
      title: 'I ступень',
      caption: 'Начало — <br class="device laptop">в любой удобный день <br class="device desktop">до %{date} %{year}. Первый дедлайн — <br class="device desktop">на седьмой день.',
      name: 'Имя и фамилия',
      city: 'Город',
      profession: 'Должность',
      about: 'О себе',
      tel: 'Телефон',
      telegram: 'Телеграм',
      telCaption: 'Для экстренной связи',
      portfolio: 'Ссылка на портфолио',
      portfolioCaption: {
        designers: 'Нет портфолио? Придумайте. <br/>Нет сайта? Соберите на Бехансе',
        managers: 'Нет портфолио? Придумайте. <br/>Нет сайта? Соберите на Бехансе, <br>Медиуме или Редимаге',
        editors: 'Нет портфолио? Придумайте. <br/>Нет сайта? Соберите на Медиуме',
      },
      taskResult: 'Результат задания',
      taskResultCaption: 'Принимаем картинки. <br/> Вступительное задание необходимо, чтобы определить стартовый уровень и распределить бесплатные места на первой ступени',
      enrollment: 'Поступление',
      defaultEnrollment: 'Сразу',
      grantEnrollment: 'По конкурсу',
      payment: 'Платёж',
      oneTime: 'Предоплата',
      monthly: 'Частями',
      defaultEnrollmentCaption: 'Начать занятия в любой день после поступления без конкурса',
      grantEnrollmentCaption: {
        designers: 'Начать занятия в любой день, сдать вступительное задание на первой неделе и вернуть оплату в случае победы на конкурсе <br> <span class="linkWithArrow is__small"><a href="%{contestLink}" target="_blank" class="is__noUnderline"><span class="linkWithArrow-text">Вступительное задание</span><span class="linkWithArrow-arrow">❱</span></a></span>',
        managers: 'Начать занятия в любой день, сдать вступительное задание на первой неделе и вернуть оплату в случае победы на конкурсе <br> <span class="linkWithArrow is__small"><a href="%{contestLink}" target="_blank" class="is__noUnderline"><span class="linkWithArrow-text">Вступительное задание</span><span class="linkWithArrow-arrow">❱</span></a></span>',
        editors: 'Начать занятия в любой день, сдать вступительное задание на первой неделе и вернуть оплату в случае победы на конкурсе <br> <span class="linkWithArrow is__small"><a href="%{contestLink}" target="_blank" class="is__noUnderline"><span class="linkWithArrow-text">Вступительное задание</span><span class="linkWithArrow-arrow">❱</span></a></span>',
      },
      oneTimeCaption: '<span class="nowrap"><s>%{maxPrice} ₽</s></span>  <span class="nowrap">%{price} ₽</span> к оплате сразу. Вы сэкономите <span class="nowrap">15 000 ₽</span>',
      monthlyCaption: 'По <span class="nowrap">%{price} ₽</span> сразу и ещё три раза каждые четыре недели после начала учёбы',
      schoolNames: {
        designers: 'дизайнеров',
        managers: 'руководителей',
        editors: 'редакторов',
      },
      subscription: '<span>Начало — до %{date} года</span>',
      subscriptionPreorder: 'Мы напишем, когда начнутся занятия',
      subscriptionStudying: 'Занятия уже начались',
      launchpad: {
        button: 'Начать занятия сейчас',
        buttonStudying: 'Продолжить занятия',
        caption: 'И немедленно начать отсчёт дедлайнов',
        captionTerms: 'Принять кодекс бюрошника и оферту и немедленно начать отсчёт дедлайнов',
        captionStudying: 'Ближайший дедлайн: <br class="device laptop desktop">%{deadline}, 23:59',
      },
    },
    prep: {
      title: 'Подготовительные курсы',
      caption: 'Начало — в любой удобный день. <br/>Длительность — три недели. <br/>Первый дедлайн — на седьмой день.',
    },
    submitButtonCaption: {
      book: {
        self: 'Деньги спишутся <br class="device mobile"/>с карты',
        selfWithPreorder: '<span class="device mobile">Предзаказ. </span>Деньги <br class="device mobile"/>спишутся с карты',
        gift: 'Деньги спишутся <br class="device mobile"/>с карты',
        giftWithPreorder: 'Предзаказ. Деньги <br class="device mobile"/>спишутся с карты',
      },
      bookshelf: {
        self: 'Деньги спишутся <br class="device mobile"/>с карты',
        gift: 'Деньги спишутся <br class="device mobile"/>с карты',
        selfWithPreorder: '<span class="device mobile">Предзаказ. </span>Деньги <br class="device mobile"/>спишутся с карты',
        giftWithPreorder: 'Предзаказ. Деньги <br class="device mobile"/>спишутся с карты',
        upgrade: 'Деньги спишутся <br class="device mobile"/>с карты',
        upgradeWithPreorder: 'Предзаказ. Деньги <br class="device mobile"/>спишутся с карты',
      },
      prep: {
        self: 'Деньги спишутся <br class="device mobile"/>с карты',
        gift: 'Деньги спишутся <br class="device mobile"/>с карты',
      },
      school: {
        self: 'Стоимость 1 ступени. <br class="device laptop desktop">Деньги спишутся <br class="device narrowMobile">с карты',
        gift: 'Стоимость 1 ступени. <br class="device laptop desktop">Деньги спишутся <br class="device narrowMobile">с карты',
      },
      lecture: {
        self: 'Деньги спишутся <br class="device mobile"/>с карты',
        gift: 'Деньги спишутся <br class="device mobile"/>с карты',
      },
      collecture: {
        self: 'Деньги спишутся <br class="device mobile"/>с карты',
        gift: 'Деньги спишутся <br class="device mobile"/>с карты',
        upgrade: 'Деньги спишутся <br class="device mobile"/>с карты',
      },
      course: {
        self: 'Деньги спишутся <br class="device mobile"/>с карты',
        gift: 'Деньги спишутся <br class="device mobile"/>с карты',
        company: 'Деньги спишутся <br class="device mobile"/>с карты',
        companyInvoice: 'Счёт и акт придут по эл. почте',
      },
    },
    submitButtonText: {
      template: '<span class="device desktop laptop">%{text} </span>%{price} %{units}',
      templateForPayByInvoice: '%{text}<span class="device desktop laptop"> за %{price} %{units}</span>',
      book: {
        self: 'Подписаться за',
        selfWithPreorder: 'Предзаказ за',
        gift: 'Подарить за',
        giftWithPreorder: 'Подарить за',
      },
      bookshelf: {
        gift: 'Подарить за',
        self: 'Подписаться за',
        selfWithPreorder: 'Предзаказ за',
        giftWithPreorder: 'Подарить за',
        upgrade: 'Переключить за',
        upgradeWithPreorder: 'Переключить за',
      },
      prep: {
        self: 'Записаться за',
        gift: 'Подарить за',
      },
      school: {
        self: 'Поступить за',
        gift: 'Подарить за',
        selfWithPreorder: 'Поступить за',
        giftWithPreorder: 'Подарить за',
      },
      lecture: {
        self: 'Подписаться за',
        gift: 'Подарить за',
        selfWithPreorder: 'Подписаться за',
        giftWithPreorder: 'Подарить за',
      },
      collecture: {
        self: 'Подписаться за',
        gift: 'Подарить за',
        selfWithPreorder: 'Подписаться за',
        giftWithPreorder: 'Подарить за',
        upgrade: 'Переключить за',
      },
      course: {
        self: 'Записаться за',
        gift: 'Подарить за',
        company: 'Записаться за',
        companyOther: 'Записать за',
        payByInvoice: 'Записаться',
        payByInvoiceOther: 'Записать',
      },
    },
    giftButtonText: 'Подарок',
    saveButtonText: 'Сохранить',
    saveButtonTextSuccess: 'Сохранено',
    buyButtonText: {
      book: {
        default: 'Подписка',
        preorder: 'Предзаказ',
        subscribed: '✓ Подписка',
        subscribedPreorder: '✓ Предзаказ',
      },
      bookshelf: {
        default: 'Подписка',
        upgrade: 'Переключение <span class="is__hiddenOnMobile">на полку</span>',
        upgradeCompact: 'Переключение',
        subscribed: '✓ Подписка',
        preorder: 'Предзаказ',
        subscribedPreorder: '✓ Предзаказ',
      },
      prep: {
        default: 'Запись',
        subscribed: '✓ Запись',
      },
      school: {
        default: 'Поступление',
        preorder: 'Поступление',
        subscribed: '✓ Поступление',
        subscribedPreorder: '✓ Поступление',
      },
      lecture: {
        default: 'Подписка',
        subscribed: '✓ Подписка',
      },
      collecture: {
        default: 'Подписка',
        subscribed: '✓ Подписка',
        upgrade: 'Переключение <span class="is__hiddenOnLaptopAndMobile">на коллекцию</span>',
        upgradeCompact: 'Переключение',
      },
      course: {
        default: 'Запись',
        subscribed: '✓ Запись',
      },
    },
    moreButtonText: {
      book: 'Подписать <span class="device laptop desktop">кого-то </span>ещё...',
      bookshelf: 'Подписать <span class="device laptop desktop">кого-то </span>ещё...',
      prep: 'Записать <span class="device laptop desktop">кого-то </span>ещё...',
      school: 'Поступить <span class="device laptop desktop">кому-то </span>ещё…',
      lecture: 'Подписать <span class="device laptop desktop">кого-то </span>ещё...',
      collecture: 'Подписать <span class="device laptop desktop">кого-то </span>ещё...',
      course: 'Записать <span class="device laptop desktop">кого-то </span>ещё...',
    },
    success: {
      book: {
        self: '✓ Вы подписаны',
        gift: '✓ Подписка оформлена',
        company: '✓ Вы подписаны',
      },
      bookshelf: {
        self: '✓ Вы подписаны',
        gift: '✓ Подписка оформлена',
        company: '✓ Вы подписаны',
      },
      prep: {
        self: '✓ Вы записаны',
        gift: '✓ Подарок оформлен',
        company: '✓ Вы записаны',
      },
      school: {
        self: '✓ Вы поступили',
        gift: '✓ Подарок оформлен',
      },
      lecture: {
        self: '✓ Вы подписаны',
        gift: '✓ Подписка оформлена',
      },
      collecture: {
        self: '✓ Вы подписаны',
        gift: '✓ Подписка оформлена',
        company: '✓ Вы подписаны',
      },
      course: {
        self: '✓ Вы записаны',
        gift: '✓ Подарок оформлен',
        company: '✓ Вы записаны',
      },
    },
    cancelled: 'Вы отписаны',
    successCaption: {
      book: 'С автопродлением',
      bookWithPreorder: 'Подписка пока не тикает <a class="is__noUnderline newPaybar-help" href="/books/faq/#ticking" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a> <br>Мы напишем, когда книга выйдет',
      bookWithPreorderCannotRead: 'Подписка пока не тикает <a class="is__noUnderline newPaybar-help" href="/books/faq/#ticking" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a> <br>Мы напишем, когда книга выйдет',
      bookshelf: 'С автопродлением',
      bookshelfWithPreorder: 'Подписка пока не тикает <a class="is__noUnderline newPaybar-help" href="/books/faq/#ticking" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a> <br>Мы напишем, когда книги выйдут',
      bookshelfWithPreorderCannotRead: 'Подписка пока не тикает <a class="is__noUnderline newPaybar-help" href="/books/faq/#ticking" target="_blank"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><path d="M8.757 15.286a1.127 1.127 0 0 0 1.05 1.029 1.271 1.271 0 0 0 1.239-1.155A1.115 1.115 0 0 0 10 14.089a1.294 1.294 0 0 0-1.239 1.2m-.8-9.807l.567.924a3.453 3.453 0 0 1 2.247-.987 1.33 1.33 0 0 1 1.512 1.428c0 1.407-2.1 2.184-2.814 3.129a3.271 3.271 0 0 0-.189 2.436h.987a2.71 2.71 0 0 1 .5-2.142c.8-.756 2.94-1.869 2.94-3.57 0-1.638-1.092-2.478-2.751-2.478a3.987 3.987 0 0 0-3 1.26" /><path d="M10.5 21A10.5 10.5 0 1 1 21 10.5 10.512 10.512 0 0 1 10.5 21zm0-20a9.5 9.5 0 1 0 9.5 9.5A9.511 9.511 0 0 0 10.5 1z" opacity=".5" class="semitransparent"/></svg></a> <br>Мы напишем, когда книги выйдут',
      lecture: 'С автопродлением',
      collecture: 'С автопродлением',
    },
    tabs: {
      bookshelf: {
        ...DEFAULT_TABS,
        mobileSelf: 'Подписка себе',
        upgrade: 'Спецпредложение',
        company: 'Для компании',
      },
      book: {
        ...DEFAULT_TABS,
        mobileSelf: 'Подписка себе',
        company: 'Для компании',
      },
      prep: {
        ...DEFAULT_TABS,
        mobileSelf: 'Себе',
        company: 'Для компании',
      },
      school: {
        ...DEFAULT_TABS,
        mobileSelf: 'Себе',
      },
      lecture: {
        ...DEFAULT_TABS,
        mobileSelf: 'Подписка себе',
      },
      collecture: {
        ...DEFAULT_TABS,
        mobileSelf: 'Подписка себе',
        upgrade: 'Спецпредложение',
        company: 'Для компании',
      },
      course: {
        ...DEFAULT_TABS,
        mobileSelf: 'Себе',
        company: 'От компании',
      },
    },
    pricingInfo: {
      subscription_1: {
        book: {
          self: 'Подписка минимум на три месяца, <br/>c автопродлением<span class="device mobile">.</span>',
          selfWithPreorder: 'При предзаказе <span class="is__colored">месяц в подарок.</span> <br/>Подписка c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/мес.</span>',
          giftWithPreorder: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/мес.</span>',
        },
        bookshelf: {
          self: 'Подписка минимум на три месяца, <br/>c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/мес.</span>',
          upgrade: 'Подписка c автопродлением<span class="device mobile">.</span>',
        },
        collecture: {
          self: 'Подписка минимум на три месяца, <br/>c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/мес.</span>',
          upgrade: 'Подписка c автопродлением<span class="device mobile">.</span>',
          upgradeWithFreeMonths: 'При переключении <span class="is__colored">2 месяца в подарок.</span> <br/>Подписка минимум на год, c автопродлением<span class="device mobile">.</span>',
        },
      },
      subscription_12: {
        book: {
          self: 'Подписка минимум на год, <br/>c автопродлением<span class="device mobile">.</span>',
          selfWithPreorder: 'При предзаказе <span class="is__colored">2 месяца в подарок.</span> <br/>Подписка c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span><span class="device mobile">.</span>',
          giftWithPreorder: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span><span class="device mobile">.</span>',
        },
        bookshelf: {
          self: 'Подписка минимум на год, <br/>c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span><span class="device mobile">.</span>',
          selfWithPreorder: 'При предзаказе <span class="is__colored">2 месяца в подарок.</span> <br/>Подписка c автопродлением<span class="device mobile">.</span>',
          giftWithPreorder: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span><span class="device mobile">.</span>',
          upgrade: 'Подписка c автопродлением<span class="device mobile">.</span>',
          upgradeWithPreorder: 'Подписка c автопродлением<span class="device mobile">.</span>',
          upgradeWithFreeMonths: 'При переключении <span class="is__colored">2 месяца в подарок.</span> <br/>Подписка минимум на год, c автопродлением<span class="device mobile">.</span>',
        },
        lecture: {
          self: 'Подписка минимум на год, <br/>c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span><span class="device mobile">.</span>',
        },
        collecture: {
          self: 'Подписка минимум на год, <br/>c автопродлением<span class="device mobile">.</span>',
          gift: 'Без дополнительных списаний.<br/>Получатель подарка сможет привязать карту и продлить подписку по сниженному тарифу <span class="nowrap">%{price} ₽/год</span><span class="device mobile">.</span>',
          upgrade: 'Подписка c автопродлением<span class="device mobile">.</span>',
          upgradeWithFreeMonths: 'При переключении <span class="is__colored">2 месяца в подарок.</span> <br/>Подписка минимум на год, c автопродлением<span class="device mobile">.</span>',
        },
      },
      prep: {
        self: '<span class="highlighted">🕑 Цена повышается каждую неделю. </span> <br/>Раньше занятия — ниже цена и выше балл',
        gift: '<span class="highlighted">🕑 Цена повышается каждую неделю. </span> <br/>Раньше занятия — ниже цена и выше балл',
        coupon: '<span class="highlighted">🕑 До %{couponEndDate} </span> <br/>Раньше занятия — ниже цена и выше балл',
      },
      school: {
        self: '<span class="highlighted">🕑 Стоимость растёт еженедельно. </span>Раньше учёба — ниже стоимость и выше шанс на бесплатное место',
        gift: '<span class="highlighted">🕑 Стоимость растёт еженедельно. </span>Раньше учёба — ниже стоимость и выше шанс на бесплатное место',
        coupon: '<span class="highlighted">🕑 До %{couponEndDate} </span>',
      },
      prevPrice: '<s>%{price}</s>',
      nextPriceSince: 'C %{date}',
      customPricingBottomCaption: '%{startText} <span class="is__gift is__colored">в подарок.</span> <br/>Подписка c автопродлением<span class="device mobile">.</span>',
    },
    giftMessage: {
      bookshelf: DEFAULT_GIFT_MESSAGES,
      book: DEFAULT_GIFT_MESSAGES,
      lecture: DEFAULT_GIFT_MESSAGES,
      collecture: DEFAULT_GIFT_MESSAGES,
    },
    successScreen: {
      book: {
        self: {
          heading: 'Подписка оформлена',
          headingWithPreorder: 'Предзаказ оформлен',
          access: 'Читать',
          accessText: 'По адресу <b>%{email}</b><br/>отправлена ссылка для доступа к книге',
          accessTextCannotRead: 'Мы напишем по адресу <b>%{email}</b>,<br/>когда книга выйдет',
          text: 'Деньги списаны с вашей карты',
        },
        gift: {
          heading: 'Подписка в подарок оформлена',
          headingWithPreorder: 'Предзаказ в подарок оформлен',
          accessText: 'По адресу <b>%{presenteeEmail}</b><br/>отправлена ссылка для доступа к книге',
          accessTextCannotRead: 'По адресу <b>%{presenteeEmail}</b> <br/>отправлено письмо о подарке',
          text: 'Деньги списаны с вашей карты',
        },
      },
      bookshelf: {
        self: {
          heading: 'Подписка оформлена',
          headingWithPreorder: 'Предзаказ оформлен',
          access: 'Читать',
          accessText: 'По адресу <b>%{email}</b><br/>отправлена ссылка для доступа к полке',
          accessTextCannotRead: 'Мы напишем по адресу <b>%{email}</b>,<br/>когда книга выйдет',
          text: 'Деньги списаны с вашей карты',
        },
        gift: {
          heading: 'Подписка в подарок оформлена',
          headingWithPreorder: 'Предзаказ в подарок оформлен',
          accessText: 'По адресу <b>%{presenteeEmail}</b><br/>отправлена ссылка для доступа к полке',
          accessTextCannotRead: 'По адресу <b>%{presenteeEmail}</b> <br/>отправлено письмо о подарке',
          text: 'Деньги списаны с вашей карты',
        },
        upgrade: {
          heading: 'Подписка переключена',
          headingWithPreorder: 'Подписка переключена',
          text: 'Деньги списаны с вашей карты',
          accessText: 'По адресу <b>%{email}</b><br/>отправлена ссылка для доступа к полке',
        },
      },
      prep: {
        self: {
          heading: 'Вы записались на подготовительные курсы',
          access: 'Начать <span class="device laptop desktop">занятия </span><span class="is__hiddenOnNarrowMobile">сейчас</span>',
          accessText: 'По адресу <b>%{email}</b> отправлена ссылка <br class="device desktop laptop"/>для доступа к Кабинету. Начните занятия до %{startUntil} года',
          text: 'Деньги списаны с вашей карты',
          textNoAccess: 'Деньги списаны с вашей карты',
          textWithDate: 'Деньги списаны с вашей карты. Начните занятия до %{startUntil} года',
        },
        gift: {
          heading: 'Вы подарили подготовительные курсы',
          accessText: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету.<br />Тот, кому вы дарите, сможет начать занятия до %{startUntil} года',
          text: 'Деньги списаны с вашей карты',
          textNoAccess: 'Деньги списаны с вашей карты',
          textNoAccessUser: 'Деньги списаны с вашей карты',
        },
      },
      school: {
        self: {
          heading: 'Вы поступили <br>в Школу %{schoolName}',
          access: 'Начать <span class="device laptop desktop">занятия </span><span class="is__hiddenOnNarrowMobile">сейчас</span>',
          accessText: 'По адресу <b>%{email}</b> отправлена ссылка <br class="device desktop laptop"/>для доступа к Кабинету. Начните занятия до %{startUntil} года',
          accessTextPreorder: 'Мы напишем по адресу <b>%{email}</b>, <br>когда начнутся занятия',
          text: 'Деньги списаны с вашей карты',
          textNoAccessUser: 'Деньги списаны с вашей карты. <br class="device mobile">Мы напишем, <br class="device laptop desktop">когда начнутся занятия',
          textNoAccess: 'Деньги списаны с вашей карты',
          textWithDate: 'Деньги списаны с вашей карты. Начните занятия до %{startUntil} года',
        },
        gift: {
          heading: 'Вы подарили 1 ступень <br>в Школе %{schoolName}',
          accessText: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету.<br />Тот, кому вы дарите, сможет начать занятия до %{startUntil} года',
          accessTextPreorder: 'По адресу <b>%{email}</b> <br>отправлено письмо о подарке',
          text: 'Деньги списаны с вашей карты',
          textNoAccessUser: 'Деньги списаны с вашей карты',
          textNoAccess: 'Деньги списаны с вашей карты',
        },
      },
      lecture: {
        self: {
          heading: 'Подписка оформлена',
          access: 'Смотреть',
          accessText: 'По адресу <b>%{email}</b><br/>отправлена ссылка для доступа к лекциям',
          text: 'Деньги списаны с вашей карты',
          supportCaption: 'Если вы хотите привязать доступ к другому адресу,<br class="device laptop desktop"/> напишите об этом: <a href="mailto:lectures@bureau.ru">lectures@bureau.ru</a>',
        },
        gift: {
          heading: 'Подписка в подарок оформлена',
          accessText: 'По адресу <b>%{email}</b><br/>отправлена ссылка для доступа к лекциям',
          text: 'Деньги списаны с вашей карты',
          supportCaption: 'Если вы хотите привязать доступ к другому адресу,<br class="device laptop desktop"/> напишите об этом: <a href="mailto:lectures@bureau.ru">lectures@bureau.ru</a>',
        },
      },
      collecture: {
        self: { access: 'Смотреть' },
        productTypes: {
          all: 'лекции и коллекции',
          lectures: {
            one: 'лекцию «%{title}» с %{author}',
            many: 'лекции',
          },
          collectures: {
            one: 'коллекцию',
            many: 'коллекции',
          },
        },
        previousCanceledCaption: 'Предыдущая подписка на %{productTypes} отменена.',
        upgrade: {
          heading: 'Подписка переключена',
          text: 'Деньги списаны с вашей карты',
          accessText: 'По адресу <b>%{email}</b><br/>отправлена ссылка для доступа к коллекции',
        },
      },
      course: {
        self: {
          heading: 'Вы записались на курс',
          text: 'Деньги списаны с вашей карты',
          accessText: 'Курс начнётся %{date}. Лекции<br class="device laptop desktop"/> первой недели уже доступны. По адресу<br class="device laptop desktop"/> <b>%{email}</b> отправлена ссылка<br class="device laptop desktop"/> для доступа к Кабинету',
          accessTextNoLectures: 'Курс начнётся %{date}. По адресу<br class="device laptop desktop"/> <b>%{email}</b> отправлена ссылка<br class="device laptop desktop"/> для доступа к Кабинету',
          authenticatedText: 'Деньги списаны с вашей карты. <br class="device mobile"/>Курс начнётся %{date}. <br/>Лекции первой недели уже доступны',
          authenticatedTextNoLectures: 'Деньги списаны с вашей карты. <br class="device mobile"/>Курс начнётся %{date}',
          access: 'Участвовать',
        },
        gift: {
          heading: 'Вы подарили курс',
          text: 'Деньги списаны с вашей карты',
          accessText: 'Приглашение отправлено по адресу<br/><b>%{email}</b>',
        },
        company: {
          heading: 'Вы записали участника на курс',
          text: 'Деньги списаны с вашей карты',
          invoicePaymentOnWorkdays: 'Оплатите счёт в течение пяти рабочих дней,<br class="device laptop desktop"/> чтобы сохранить место в группе',
          invoicePaymentBeforeDeadline: 'Оплатите счёт до %{date}, чтобы сохранить место в группе',
          accessTextPaidByCard: 'Приглашение отправлено по адресу<br/><b>%{email}</b>',
          accessTextPaidByInvoice: 'Документы для бухгалтерии отправлены<br class="device laptop desktop"/> по адресу <b>%{email}</b>',
        },
      },
      close: 'Закрыть',
      lock: '🔓',
      previousSubscriptionCanceled: {
        one: '<br class="device mobile">Предыдущая подписка на книгу «%{bookTitle}» отменена.',
        many: '<br class="device mobile">Предыдущая подписка на книги отменена.',
      },
    },
    title: {
      book: {
        gift: 'Подписка в подарок <span class="device mobile">на электронную книгу <small>%{productTitle}</small></span>',
        self: 'Подписка <span class="device mobile">на электронную книгу <small>%{productTitle}</small></span>',
        company: 'Подписка для компании <span class="device mobile">на электронную книгу <small>%{productTitle}</small></span>',
      },
      bookshelf: {
        gift: 'Подписка в подарок <span class="device mobile">на электронную полку <small>%{productTitle}</small></span>',
        self: 'Подписка <span class="device mobile">на электронную полку <small>%{productTitle}</small></span>',
        upgrade: 'Подписка на электронную полку <small class="module device mobile">%{productTitle}</small>',
        company: 'Подписка для компании <span class="device mobile">на электронную полку <small>%{productTitle}</small></span>',
      },
      prep: {
        self: 'Запись на подготовительные курсы',
        gift: 'Запись на подготовительные курсы в подарок',
        company: 'Запись на подготовительные курсы для компании',
      },
      school: {
        self: 'Поступление в Школу %{productTitle}',
        gift: 'Подарить 1 ступень в Школе %{productTitle}',
      },
      lecture: {
        self: 'Подписка <span class="device mobile">на лекции <small>%{productTitle}</small></span>',
        gift: 'Подписка в подарок <span class="device mobile">на лекции <small>%{productTitle}</small></span>',
      },
      collecture: {
        self: 'Подписка <span class="device mobile">на коллекцию <small>%{productTitle}</small></span>',
        gift: 'Подписка в подарок <span class="device mobile">на коллекцию <small>%{productTitle}</small></span>',
        upgrade: 'Подписка на коллекцию <small class="module device mobile">%{productTitle}</small>',
        company: 'Подписка для компании на лекции',
      },
      course: {
        self: 'Запись на курс<br/>«%{productTitle}»',
        gift: 'Запись на курс<br/>«%{productTitle}»',
        company: 'Запись на курс<br/>«%{productTitle}»',
      },
    },
    links: {
      license: 'Оферта и возврат',
      cancellation: 'Отмена подписки',
      offer: 'Оферта',
      offerAndContract: 'Оферта и договор',
      codex: 'Кодекс бюрошника',
      cancellations: {
        bookshelf: 'https://docs.google.com/document/d/1-s0oCxp9itvZpUrRh9-9wcv442qs0j5L5-GSAVmwCKA',
        book: 'https://docs.google.com/document/d/1-s0oCxp9itvZpUrRh9-9wcv442qs0j5L5-GSAVmwCKA',
      },
      licenses: {
        bookshelf: 'https://docs.google.com/document/d/19xgwkXq0QFW1CHaGy1JMwYSThaMLoh0M6Q2s0F0L1y4',
        book: 'https://docs.google.com/document/d/19xgwkXq0QFW1CHaGy1JMwYSThaMLoh0M6Q2s0F0L1y4',
      },
      course: { license: 'Оферта' },
      book: {
        license: 'Оферта и возврат',
        cancellation: 'Отмена подписки',
      },
      bookshelf: {
        license: 'Оферта и возврат',
        cancellation: 'Отмена подписки',
      },
      lecture: {
        license: 'Оферта и возврат',
        cancellation: 'Отмена подписки',
      },
      collecture: {
        license: 'Оферта и возврат',
        cancellation: 'Отмена подписки',
      },
      school: { license: 'Оферта и договор' },
      prep: { license: 'Оферта и договор' },
    },
    notice: {
      bookshelf: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к полке',
          textCannotRead: 'Мы напишем по адресу <b>%{email}</b>, когда книга выйдет',
          buttonText: 'Отправить снова',
        },
        subscriptionUpdateFailed: '<b>Не удалось сохранить параметры подписки.</b> Попробуйте ещё раз. Если ошибка будет повторяться, напишите нам: <a href="mailto:books@artgorbunov.ru" style="text-decoration: none; background: none;">books@artgorbunov.ru</a>',
      },
      book: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к книге',
          textCannotRead: 'Мы напишем по адресу <b>%{email}</b>, когда книга выйдет',
          buttonText: 'Отправить снова',
        },
      },
      prep: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету',
          buttonText: 'Отправить снова',
        },
      },
      school: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету',
          textNoAccess: 'Мы напишем по адресу <b>%{email}</b>, когда начнутся занятия',
          buttonText: 'Отправить снова',
        },
      },
      course: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к Кабинету',
          textNoAccess: 'Мы напишем по адресу <b>%{email}</b>, когда курс начнётся',
          buttonText: 'Отправить снова',
        },
      },
      lecture: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к лекциям',
          buttonText: 'Отправить снова',
        },
      },
      collecture: {
        emailSent: {
          text: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к коллекции',
          buttonText: 'Отправить снова',
        },
        subscriptionUpdateFailed: '<b>Не удалось сохранить параметры подписки.</b> Попробуйте ещё раз. Если ошибка будет повторяться, напишите нам: <a href="mailto:lectures@bureau.ru" style="text-decoration: none; background: none;">lectures@bureau.ru</a>',
      },
      paymentFailed: '<b>Оплата не прошла.</b> Попробуйте снова или введите данные другой банковской карты',
      paymentFailedActionable: '<b>Оплата не прошла.</b> Попробуйте снова или введите данные <a href="javascript:;">другой банковской карты</a>',
      paymentFailedAmountExceed: '<b>Недостаточно средств.</b> Введите данные другой банковской карты',
      paymentFailedAmountExceedActionable: '<b>Недостаточно средств.</b> Введите данные <a href="javascript:;">другой банковской карты</a>',
      paymentFailedForeignIssuerBlockedCard: '<b>Оплата по иностранной карте не прошла.</b> Выберите способ <a href="/pay/international/%{paybarAccessToken}">для иностранных карт</a>',
      paymentFailedForeignIssuerBlockedCardCompletely: '<b>Оплата по иностранной карте не прошла.</b> Введите данные другой банковской карты или <a href="mailto:%{productEmail}">напишите нам для решения проблемы</a>',
      subscriptionCancellationFailed: '<b>Не удалось отменить подписку.</b> Попробуйте ещё раз. Если ошибка будет повторяться, напишите нам: <a href="mailto:books@artgorbunov.ru" style="text-decoration: none; background: none;">books@artgorbunov.ru</a>',
      subscriptionCancellationRevertFailed: '<b>Не удалось восстановить подписку.</b> Попробуйте ещё раз. Если ошибка будет повторяться, напишите нам: <a href="mailto:books@artgorbunov.ru" style="text-decoration: none; background: none;">books@artgorbunov.ru</a>',
    },
    userCardNumber: 'Карта',
    cardSwitch: {
      heading: 'Оплата картой',
      existingCard: '%{userCardNumber}',
      card: 'Pусской',
      internationalCard: 'Иностранной',
      newCard: 'Другой русской',
      newInternationalCard: 'Иностранной',
    },
    launchpad: {
      book: 'Читать',
      bookNoAccess: 'Подписка',
      lecture: 'Смотреть',
      lectureNoAccess: 'Подписка',
      school: 'Учиться',
      schoolPreorder: 'Скоро занятия',
      schoolNoAccess: 'Поступление в Школу %{schoolName}',
      prepNoAccess: 'Запись на подготовительные курсы',
      course: 'Участвовать',
      courseNoAccess: 'Курс %{startDate} — %{endDate}',
    },
    upgrade: {
      titleStart: 'Подписчикам на ',
      text: 'Переключите свою действующую подписку на «Учебники бюро». Предыдущая подписка на книгу отменится автоматически.',
    },
    specialPriceLabel: 'Спеццена подписчикам',
    ribbonNotReady: 'Скоро',
    upgradeTitle: {
      one: 'Подписчикам на %{count} отдельную книгу',
      few: 'Подписчикам на %{count} отдельные книги',
      many: 'Подписчикам на 3 и более отдельные книги',
      all: 'Подписчикам %{products}',
    },
    upgradeText: {
      one: 'Переключите свою действующую подписку на «%{shelfName}». Предыдущая подписка на книгу отменится автоматически.',
      few: 'Переключите свою действующую подписку на «%{shelfName}». Предыдущая подписка на книги отменится автоматически.',
      many: 'Переключите свою действующую подписку на «%{shelfName}». Предыдущая подписка на книги отменится автоматически.',
      all: 'Переключите свою действующую подписку для доступа ко всем книгам Издательства бюро. Вся предыдущая подписка %{products} отменится автоматически.',
    },
    upgradeProducts: {
      books: 'на отдельные <br class="device laptop desktop"/>книги',
      bookshelves: 'на электронные полки',
      all: 'на отдельные книги и электронные полки',
    },
    companyPlanText: 'До %{maxEmployees} сотрудников',
    course: {
      participant: {
        self: 'Участник',
        gift: 'Кому дарите?',
        company: 'Участник',
      },
      participantName: 'Имя и фамилия',
      organization: 'Место работы',
      studentCheckbox: {
        self: 'Я студент',
        gift: 'Студенту',
        company: 'Студент',
      },
      instituteTitle: 'Вуз',
      instituteTel: 'Тел. деканата',
      name: {
        self: 'Имя и фамилия',
        gift: 'Ваши имя и фамилия',
        company: 'Ваши имя и фамилия',
      },
      payer: 'Плательщик',
      companyTitle: 'Юр. название',
      companyCEO: 'Директор',
      companyCEOPlaceholder: 'И. О. Фамилия',
      companyEmail: 'Эл. почта плательщика',
      companyEmailCaption: 'Для документов',
      companyOtherEmailCaption: 'Для доступа к курсу и получения сертификата',
      paymentType: 'Платёж',
      paymentTypeSwitch: {
        card: 'Банковской картой',
        invoice: 'По счёту',
      },
      participantTypeSwitch: {
        self: 'Я сам',
        other: 'Кто-то другой',
      },
      howTo: 'Как участвовать и оформить документы',
      howToCaption: {
        remote: {
          self: {
            invoiceOnWorkdays: 'Запишитесь на курс. Счёт, акт и оферта придут по электронной почте. <ol><li>Оплатите счёт в течение пяти рабочих дней после записи на курс, чтобы сохранить место в группе.</li> <li>Накануне курса мы пришлём вам письмо с доступом в учебный Кабинет и приглашением в чат, где участники курса общаются с преподавателем и публикуются ссылки на видеоконференции.</li> <li>Распечатайте и подпишите акт в вашей организации и передайте в Бюро Горбунова: Большая Новодмитровская улица, 36, стр. 2, Коворкафе, Москва, Россия, 127015.</li> <li>Мы доставим счёт, акт и экземпляр оферты обратно в течение пяти дней с момента получения акта в бюро.</li></ol>',
            invoiceBeforeDeadline: 'Запишитесь на курс. Счёт, акт и оферта придут по электронной почте. <ol><li>Оплатите счёт до %{date}, чтобы сохранить место в группе.</li> <li>Накануне курса мы пришлём вам письмо с доступом в учебный Кабинет и приглашением в чат, где участники курса общаются с преподавателем и публикуются ссылки на видеоконференции.</li> <li>Распечатайте и подпишите акт в вашей организации и передайте в Бюро Горбунова: Большая Новодмитровская улица, 36, стр. 2, Коворкафе, Москва, Россия, 127015.</li> <li>Мы доставим счёт, акт и экземпляр оферты обратно в течение пяти дней с момента получения акта в бюро.</li></ol>',
            card: 'Запишитесь на курс. Электронный чек, квитанция, акт и оферта придут по электронной почте.<ol><li>Накануне курса мы пришлём вам письмо с доступом в учебный Кабинет и приглашением в чат, где участники курса общаются с преподавателем и публикуются ссылки на видеоконференции.</li> <li>Распечатайте и подпишите акт в вашей организации и передайте в Бюро Горбунова: Большая Новодмитровская улица, 36, стр. 2, Коворкафе, Москва, Россия, 127015.</li> <li>Мы доставим счёт, акт и экземпляр оферты обратно в течение пяти дней с момента получения акта в бюро.</li></ol>',
          },
          other: {
            invoiceOnWorkdays: 'Запишите участника на курс. Счёт, акт и оферта придут по электронной почте. <ol><li>Оплатите счёт в течение пяти рабочих дней после записи на курс, чтобы сохранить место в группе.</li> <li>Накануне курса мы пришлём участнику письмо с доступом в учебный Кабинет и приглашением в чат, где участники курса общаются с преподавателем и публикуются ссылки на видеоконференции.</li> <li>Распечатайте и подпишите акт в вашей организации и передайте в Бюро Горбунова: Большая Новодмитровская улица, 36, стр. 2, Коворкафе, Москва, Россия, 127015.</li> <li>Мы доставим счёт, акт и экземпляр оферты обратно в течение пяти дней с момента получения акта в бюро.</li></ol>',
            invoiceBeforeDeadline: 'Запишите участника на курс. Счёт, акт и оферта придут по электронной почте. <ol><li>Оплатите счёт до %{date}, чтобы сохранить место в группе.</li> <li>Накануне курса мы пришлём участнику письмо с доступом в учебный Кабинет и приглашением в чат, где участники курса общаются с преподавателем и публикуются ссылки на видеоконференции.</li> <li>Распечатайте и подпишите акт в вашей организации и передайте в Бюро Горбунова: Большая Новодмитровская улица, 36, стр. 2, Коворкафе, Москва, Россия, 127015.</li> <li>Мы доставим счёт, акт и экземпляр оферты обратно в течение пяти дней с момента получения акта в бюро.</li></ol>',
            card: 'Запишите участника на курс. Электронный чек, квитанция, акт и оферта придут по электронной почте.<ol><li>Накануне курса мы пришлём участнику письмо с доступом в учебный Кабинет и приглашением в чат, где участники курса общаются с преподавателем и публикуются ссылки на видеоконференции.</li> <li>Распечатайте и подпишите акт в вашей организации и передайте в Бюро Горбунова: Большая Новодмитровская улица, 36, стр. 2, Коворкафе, Москва, Россия, 127015.</li> <li>Мы доставим счёт, акт и экземпляр оферты обратно в течение пяти дней с момента получения акта в бюро.</li></ol>',
          },
        },
      },
      launchpad: { button: 'Учебный кабинет' },
      lastSignupDate: 'Запись до %{endsAt}',
      signupMotivation: '<span class="highlighted">🕑 Раньше запись — ниже стоимость.</span>',
      couponSignupMotivation: '<span class="highlighted">🕑 До %{couponEndsAt}. Раньше запись — ниже стоимость.</span>',
      couponSignupMotivationShort: '<span class="highlighted">🕑 До %{couponEndsAt}.</span>',
      seatsLeft: {
        one: 'Осталось <b class="is__highlighted">%{count} место</b>',
        few: 'Осталось <b class="is__highlighted">%{count} места</b>',
        many: 'Осталось <b class="is__highlighted">%{count} мест</b>',
      },
      schedule: 'Каждую неделю — новые видеолекции, задания и видеоконференции с преподавателем<span class="device mobile">.</span>',
      subscription: '<span>Начало — %{startsAt} года</span>',
      subscriptionStudying: 'Курс уже идёт',
    },
  },

  realtimeCountdown: {
    days: {
      one: 'день',
      few: 'дня',
      many: 'дней',
    },
    hours: {
      one: 'час',
      few: 'часа',
      many: 'часов',
    },
    minutes: {
      one: 'минута',
      few: 'минуты',
      many: 'минут',
    },
    seconds: {
      one: 'секунда',
      few: 'секунды',
      many: 'секунд',
    },
    short: {
      days: 'д',
      hours: 'ч',
      minutes: 'м',
      seconds: 'с',
    },
  },

  subscriptionCancel: {
    validUntil: {
      book: 'Подписка на книгу оплачена до %{validUntil}',
      bookPreorder: 'Подписка предоплачена на один год и два месяца. Срок затикает ближе к полному выходу книги',
      bookshelf: 'Подписка на полку оплачена до %{validUntil}',
      bookshelfPreorder: 'Подписка предоплачена на один год и два месяца. Срок затикает ближе к полному выходу полки',
      lecture: 'Подписка на лекции оплачена до %{validUntil}',
      collecture: 'Подписка на коллекцию оплачена до %{validUntil}',
    },
    disclaimer: {
      book: 'Если отменить подписку сейчас, доступ к книге будет закрыт. Сниженный тариф после первого года действует только при непрерывной подписке.',
      bookshelf: 'Если отменить подписку сейчас, доступ к полке будет закрыт. Сниженный тариф после первого года действует только при непрерывной подписке.',
      lecture: 'Если отменить подписку сейчас, доступ к лекциям будет закрыт. Сниженный тариф после первого года действует только при непрерывной подписке.',
      collecture: 'Если отменить подписку сейчас, доступ к лекциям будет закрыт. Сниженный тариф после первых трёх месяцев действует только при непрерывной подписке.',
    },
    emailCancellation: { lecture: 'Интерфейс отмены подписки пока в работе, если вы хотите отменить подписку, напишите: <a href="mailto:lectures@bureau.ru">lectures@bureau.ru</a>' },
    cancellationText: {
      book: 'При отмене подписки читатели потеряют доступ к книгам. Если вы хотите отменить подписку, напишите: <a href="mailto:books@bureau.ru">books@bureau.ru</a>',
      bookshelf: 'При отмене подписки читатели потеряют доступ к книгам. Если вы хотите отменить подписку, напишите: <a href="mailto:books@bureau.ru">books@bureau.ru</a>',
      lecture: 'При отмене подписки зрители потеряют доступ к лекциям. Если вы хотите отменить подписку, напишите: <a href="mailto:lectures@bureau.ru">lectures@bureau.ru</a>',
      collecture: 'При отмене подписки зрители потеряют доступ к лекциям. Если вы хотите отменить подписку, напишите: <a href="mailto:lectures@bureau.ru">lectures@bureau.ru</a>',
    },
    buttonKeep: 'Оставить<span class="device laptop desktop"> подписку</span>',
    buttonKeepCaption: 'И использовать оплаченное время',
    buttonCancel: 'Отменить<span class="device laptop desktop"> подписку</span>',
    buttonCancelCaption: {
      book: 'И сразу же отключить доступ к книге',
      bookshelf: 'И сразу же отключить доступ к полке',
      lecture: 'И сразу же отключить доступ к лекциям',
      collecture: 'И сразу же отключить доступ к лекциям',
    },
  },

  lastChance: {
    subscriptionCancelled: {
      book: 'Подписка на книгу отменена',
      bookshelf: 'Подписка на полку отменена',
      lecture: 'Подписка на лекции отменена',
      collecture: 'Подписка на коллекцию отменена',
    },
    motivator: {
      book: 'Восстановите подписку и сохраните оплаченное время и сниженный тариф после первого года',
      bookNoTimeLeft: 'Восстановите подписку и сохраните сниженный тариф после первого года',
      bookshelf: 'Восстановите подписку и сохраните оплаченное время и сниженный тариф после первого года',
      bookshelfNoTimeLeft: 'Восстановите подписку и сохраните сниженный тариф после первого года',
      lecture: 'Восстановите подписку и сохраните оплаченное время и сниженный тариф после первого года',
      lectureNoTimeLeft: 'Восстановите подписку и сохраните сниженный тариф после первого года',
      collecture: 'Восстановите подписку и сохраните оплаченное время и сниженный тариф после первых трёх месяцев',
      collectureNoTimeLeft: 'Восстановите подписку и сохраните сниженный тариф после первых трёх месяцев',
    },
    buttonRevert: 'Восстановить подписку',
  },

  vac: { invalidLoginLink: 'К сожалению, ссылка для входа устарела. По адресу <b>%{email}</b> отправлена новая ссылка' },

  publishingSubscriptionForm: {
    heading: 'Когда что выйдет',
    caption: 'Оставьте почту, чтобы узнать о выходе книг и обновлениях других продуктов бюро.',
    captionPreorder: 'Оставьте почту, чтобы узнать о выходе книги и обновлениях других продуктов бюро.',
  },

  userPopup: {
    loginToBureausphere: 'Вход в Бюросферу',
    card: {
      default: 'Карта',
      current: 'Карта %{cardNumber}',
      missing: '<span>Привязать <span class="is__hiddenOnNarrowMobile">банковскую </span>карту</span>',
    },
    myCourse: 'Курс %{dates}',
  },

  schoolRating: {
    defaultTitle: 'Студенты',
    caption_level1: 'Рейтинг по неделям тестов и заданий.',
    caption_level2: 'Рейтинг по результатам практических заданий.',
    caption_level3: '',
    googleLink: '<a href="%{googleLink}">Подробности в Гугле</a>',
    level: '%{level} ступень',
    weekTitle: '%{number} неделя',
    weekTotal: 'За неделю',
    rating: 'Рейтинг',
    tags: {
      granted: 'Беспл.',
      grantedFull: 'На бесплатном',
      rookie: 'Нов.',
      failed: {
        male: 'Отчислен',
        female: 'Отчислена',
        unknown: 'Отчислен',
      },
      paused: 'Академ',
      blackMark: 'Чёрная метка',
    },
    empty: {
      title: 'На старт, дамы и господа!',
      caption: 'Рейтинг сформируется после первой недели обучения',
      listCaption: 'Пока никто не начал учиться. Поступайте в школу сегодня и начинайте обучение поскорее, пока конкуренция ниже. У вас будет больше шансов на бесплатное место на следующих ступенях школы.',
    },
  },

  schoolFaces: {
    titleGranted: 'На бесплатных местах',
    titleAbc: 'По алфавиту',
    titleAll: {
      one: 'Все студенты',
      few: 'Все %{count} студента',
      many: 'Все %{count} студентов',
    },
    diploma: {
      tag: 'Диплом',
      team: 'Команда: %{team}',
      artDirector: 'Арт-директор: %{artDirector}',
    },
  },

  classroom: {
    correction: 'Корректировка',
    average: 'Средняя',
  },

  soviet: {
    relatedTags: 'Копните глубже',
    switcher: {
      released: 'Советы',
      withNewComments: 'С новыми комментариями',
      scheduled: 'Готовые к публикации',
      unanswered: 'Без ответа',
      compilations: 'Подборки',
    },
    askForm: {
      name: 'Имя и фамилия',
      namePlaceholder: 'Обязательны полностью',
      email: 'Эл. почта',
      emailPlaceholder: 'Не будет опубликована',
      question: 'Текст вопроса',
      send: 'Отправить',
      error: 'Ошибка отправки. Попробуйте ещё раз',
      success: 'Ваш вопрос отправлен',
      title: 'Ваш вопрос',
      caption: 'Мы стараемся ответить на все вопросы. Кроме нашего совета, вы получите интеллигентные отзывы посетителей сайта',
      purpose: 'Цель «Советов» — обмен знаниями о дизайне продуктов, представлении информации, пользовательском интерфейсе, навигации в общественных местах, об отношениях с клиентами, о технической и юридической сторонах дизайна. Комментарии к вашему вопросу пройдут модерацию. Будут опубликованы ответы, содержащие новые мысли и интересные примеры.',
      thankYou: 'Спасибо за участие!',
      anotherQuestion: 'Задать другой вопрос',
      questionSent: 'Ваш вопрос отправлен. Пока предлагаем вам ознакомиться с уже <a href="/bb/soviet/">опубликованными ответами</a>.',
    },
  },

  school: {
    disciplineShortNames: {
      products: 'Продукт',
      typography: 'Типографика',
      ui: 'Интерфейс',
      data: 'Визуализация',
      prototyping: 'Вёрстка',
      text: 'Текст',
      law: 'Право',
      fff: 'Управление',
      clients: 'Переговоры',
      analytics: 'Маркетинг',
      prep: 'Подг. курсы',
      contests: 'Конкурс',
      bonus: 'Бонус',
      ktostudent: 'Кто студент',
      recipes: 'Рецепты',
    },
  },

  getGift: {
    title: 'Открыть подарок',
    openedTitle: 'Подписка оформлена',
    openedSubtitle: 'Срок подписки — 1 год',
    codeHint: 'Подарочный код расположен на сертификате или пришёл вам по электронной почте',
    readButton: 'Читать',
    form: {
      name: 'Имя и фамилия',
      email: 'Эл. почта',
      code: 'Подарочный код',
      errors: { code: 'Код не сработал. Попробуйте снова или свяжитесь с нами: <a href="mailto:gift@bureau.ru">gift@bureau.ru</a>' },
    },
    emailCaption: {
      book: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к книге',
      bookBreak: 'По адресу <b>%{email}</b><br />отправлена ссылка для доступа к книге',
      bookshelf: 'По адресу <b>%{email}</b> отправлена ссылка для доступа к полке',
      bookshelfBreak: 'По адресу <b>%{email}</b><br />отправлена ссылка для доступа к полке',
    },
    supportCaption: 'Если вы хотите привязать доступ к другому адресу, напишите об этом: <a href="mailto:books@bureau.ru">books@bureau.ru</a>',
  },

  discountBanner: {
    discount: 'скидка',
    extraCharge: 'наценка',
    book: {
      text: 'Успейте подписаться на книгу со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на книгу больше не действует',
    },
    bookshelf: {
      text: 'Успейте подписаться на электронную полку со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на электронную полку больше не действует',
    },
    school: {
      text: 'Успейте поступить в школу со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка для школы больше не действует',
    },
    course: {
      text: 'Успейте записаться на курс со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на курс больше не действует',
    },
    prep: {
      text: 'Успейте записаться на подготовительные курсы со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на подготовительные курсы больше не действует',
    },
    font: {
      text: 'Успейте купить шрифт со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на шрифт больше не действует',
    },
    lecture: {
      text: 'Успейте подписаться на лекции со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на лекции больше не действует',
    },
    collecture: {
      text: 'Успейте подписаться на коллекцию со скидкой до %{endsAt}',
      textTimeOver: '<b>Время закончилось</b> Скидка на коллекцию больше не действует',
    },
    withExtraCharge: {
      book: {
        text: 'Успейте подписаться на книгу с наценкой до %{endsAt}',
        textTimeOver: '<b>Время закончилось</b> Наценка на книгу больше не действует',
      },
      bookshelf: {
        text: 'Успейте подписаться на электронную полку с наценкой до %{endsAt}',
        textTimeOver: '<b>Время закончилось</b> Наценка на электронную полку больше не действует',
      },
    },
  },

  lectures: {
    courseTitle: '%{title} <span class="nowrap">c %{author}</span>',
    navigation: {
      following: { title: 'Смотреть дальше' },
      other: { title: 'Рекомендуем другие лекции' },
    },
  },

  collectures: {
    pricing: {
      title: 'Подписка для новых зрителей',
      titleUpgradeFor: {
        lectures: 'Подписчикам на отдельные лекции',
        collectures: 'Подписчикам на коллекции',
        lecturesAndCollectures: 'Подписчикам на отдельные лекции и коллекции',
      },
      subscriptionFooter: {
        caption3: 'Подписка минимум на три месяца, продлевается автоматически',
        caption12: 'Подписка минимум на год, продлевается автоматически',
        captionUpgrade7: 'Месяц <span class="is__highlighted">в подарок.</span> Подписка c автопродлением',
        captionUpgrade14: 'Два месяца <span class="is__highlighted">в подарок.</span> Подписка c автопродлением',
        captionCompact3: 'С автопродлением',
        captionCompact12: 'С автопродлением',
        captionCompactUpgrade7: 'Месяц <span class="is__highlighted">в подарок.</span> <br/>Подписка c автопродлением',
        captionCompactUpgrade14: 'Два месяца <span class="is__highlighted">в подарок.</span> <br/>Подписка c автопродлением',
      },
    },

    upgrade: {
      title: 'Подписчикам на %{productType}',
      fromLectures: 'отдельные лекции',
      fromCollectures: 'коллекции',
      fromLecturesAndCollectures: 'отдельные лекции и коллекции',
      text: 'Переключите свою подписку для доступа ко всем лекциям. Вся предыдущая подписка на %{productType} отменится автоматически.',
    },

    spacer: {
      link: 'Коллекция',
      all: {
        title: 'Все лекции бюро',
        titleLaptop: 'Все лекции',
        description: 'Подписка на все лекции, которые вышли и когда‑либо выйдут в бюро.',
        descriptionLaptop: 'Подписка на все лекции, которые вышли и когда‑либо выйдут.',
      },
    },
  },

  product: {
    email: {
      lecture: 'lectures@bureau.ru',
      collecture: 'lectures@bureau.ru',
      book: 'books@bureau.ru',
      bookshelf: 'books@bureau.ru',
      prep: 'school@bureau.ru',
      school: 'school@bureau.ru',
      course: 'courses@bureau.ru',
    },
  },

  courses: {
    openCourses: {
      seatsLeft: {
        one: 'Осталось одно место',
        few: 'Осталось %{count} места',
        many: 'Осталось %{count} мест',
      },
      priceChangeAt: 'C %{priceСhangeDate} подорожает',
      priceChangeAtCompact: 'C %{priceСhangeDate} дороже',
      enrollEndsAt: 'Запись до %{enrollEndsDate}',
      enroll: 'Записаться',
      isSoldOut: 'Места закончились',
    },
    practicalCourses: {
      duration: {
        days: {
          one: '%{inWords} день',
          few: '%{inWords} дня',
          many: '%{inWords} дней',
        },
        week: {
          one: '%{inWords} неделя',
          few: '%{inWords} недели',
          many: '%{inWords} недель',
        },
      },
    },
    durationWeeksDative: {
      3: 'трёхнедельному',
      4: 'четырёхнедельному',
    }
  },
  strategies: {
    helpers: {
      onlineCourse: {
        duration: {
          weeks: {
            1: 'одна неделя',
            2: 'две недели',
            3: 'три недели',
            4: 'четыре недели',
            5: 'пять недель',
          },
          days: {
            1: 'один день',
            2: 'два дня',
            3: 'три дня',
            4: 'четыре дня',
            5: 'пять дней',
            6: 'шесть дней',
          },
        },
      },
    },
  },
  cpd: {
    captionText: {
      sameScore: '<a class="no-u" href="/cpd/"><span class="smallcapitals">%{score} КПД</span> ❔</a><br>Дизайнеру, редактору и руководителю',
      sameScoreEditorManager: '<a class="no-u" href="/cpd/"><span class="smallcapitals">КПД</span> ❔</a><br>%{designerScore} дизайнеру, %{editorScore} редактору и руководителю',
      sameScoreDesignerEditor: '<a class="no-u" href="/cpd/"><span class="smallcapitals">КПД</span> ❔</a><br>%{designerScore} дизайнеру и редактору, %{managerScore} руководителю',
      differentScore: '<a class="no-u" href="/cpd/"><span class="smallcapitals">КПД</span> ❔</a><br>%{designerScore} дизайнеру, %{editorScore} редактору, %{managerScore} руководителю',
    },

    schoolCaptionText: {
      header: {
        designers: '<a class="no-u" href="/cpd/"><span class="title"><span class="smallcapitals">КПД</span> в Школе дизайнеров</span> ❔</a>',
        editors: '<a class="no-u" href="/cpd/"><span class="title"><span class="smallcapitals">КПД</span> в Школе редакторов</span> ❔</a>',
        managers: '<a class="no-u" href="/cpd/"><span class="title"><span class="smallcapitals">КПД</span> в Школе руководителей</span> ❔</a>',
      },
      header1: 'За первую ступень',
      header2: 'За вторую ступень',
      header3: 'За третью ступень',
      sameScore: '%{score} дизайнеру, редактору и руководителю',
      sameScoreEditorManager: '%{designerScore} дизайнеру, %{editorScore} редактору и руководителю',
      sameScoreDesignerEditor: '%{designerScore} дизайнеру и редактору, %{managerScore} руководителю',
      differentScore: '%{designerScore} дизайнеру, %{editorScore} редактору, %{managerScore} руководителю',
    },
  },
  leadMagnet: {
    openLink: 'Открыть бесплатно',
    downloadFile: 'Скачать бесплатно',
  },
  subscriptionNotifications: {
    renewMiss: 'Не удалось продлить подписку. Срок предложения истёк',
    renewFail: 'Оплата не прошла. Попробуйте снова перейти по ссылке в письме',
    renewSuccess: 'Вы возобновили подписку. Спасибо!',
    redeemMiss: 'Не удалось открыть книгу. Срок подписки истёк',
    redeemFail: 'Не получилось активировать подарок.',
    transferSuccess: 'Ваша подписка оформлена и теперь будет продлеваться автоматически',
  },
  paywall: {
    assetIsNotAccessible: {
      lecture: 'Лекция пока недоступна',
      literature: 'Литература пока недоступна',
      task: 'Задание пока недоступно',
      test: 'Тест пока недоступен',
      text: 'Лекция пока недоступна',
    },
  },
}
