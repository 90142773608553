import { connect } from 'preact-redux'

import { TextNode, Heading3 } from '../textNodes'
import { FormInput } from '../form'
import NewPaybarEmployeesTextarea from './newPaybarEmployeesTextarea'

import NewPaybarPersonalFieldsetBase from './newPaybarPersonalFieldsetBase'

import {
  setFormValidity,
  addDirtyForm,
  removeDirtyForm,
} from './newPaybarActions'

const CLEAR_ON_RELOAD_FORMS = ['company']
const PRODUCTS_WITH_FULL_PRESENTEE_NAME = ['school', 'course']

class NewPaybarPersonalFieldset extends NewPaybarPersonalFieldsetBase {
  constructor(props) {
    super(props)

    this.rememberedFields = {
      company: props.company,
      emails: props.emails,
    }

    this.validityStates = {
      payerName: !!this.props.userName,
      presenteeName: false,
      presenteeEmail: false,
      company: true,
      emails: true,
    }

    this.validityMaps = {
      self: ['payerName'],
      gift: ['payerName', 'presenteeName', 'presenteeEmail'],
      upgrade: ['payerName'],
      company: ['company', 'emails'],
    }
  }

  get payerNameLabel() {
    const isGift = this.props.type === 'gift'

    return isGift ? I18n.t('newPaybar.gift.payerName') : I18n.t('newPaybar.payerName')
  }

  render(props) {
    const hasFullPresenteeName = PRODUCTS_WITH_FULL_PRESENTEE_NAME.includes(props.product)
    const presenteeNameLabel = hasFullPresenteeName ? I18n.t('newPaybar.labels.fullName') :
      I18n.t('newPaybar.gift.presenteeName')

    return (
      <div className="newPaybarPersonalFieldset" ref={ this.bindEl }>
        { !this.props.userName &&
          <label className="newPaybarPersonalFieldset-row is__headless">
            <TextNode className="newPaybarPersonalFieldset-label">
              { this.payerNameLabel }
            </TextNode>
            <FormInput className="newPaybarPersonalFieldset-input"
              name="payerName"
              type="text"
              autoComplete="name"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.payerName }
              ref={ ref => this.fieldRefs.payerName = ref}
            />
          </label>
        }

        { props.type === 'gift' &&
          <div>
            <Heading3>{ I18n.t('newPaybar.gift.presentee') }</Heading3>

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { presenteeNameLabel }
              </TextNode>
              <FormInput className="newPaybarPersonalFieldset-input"
                name="presenteeName"
                type="text"
                required
                onValidityChange={ this.onInputValidityChange }
                onInput={ this.onInput }
                value={ this.rememberedFields.presenteeName }
                initialValue={ this.rememberedFields.presenteeName }
                ref={ ref => this.fieldRefs.presenteeName = ref}
              />
            </label>

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { I18n.t('newPaybar.gift.presenteeEmail') }
              </TextNode>
              <FormInput className="newPaybarPersonalFieldset-input"
                name="presenteeEmail"
                type="email"
                required
                onValidityChange={ this.onInputValidityChange }
                onInput={ this.onInput }
                value={ this.rememberedFields.presenteeEmail }
                initialValue={ this.rememberedFields.presenteeEmail }
                ref={ ref => this.fieldRefs.presenteeEmail = ref}
              />
            </label>
          </div>
        }

        { props.type === 'company' &&
          <div>
            <input type="hidden" name="id" value={ this.props.subscription.id } />

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label is__boldOnMobile">
                { I18n.t('newPaybar.company.plan') }
              </TextNode>
              <TextNode className="newPaybarPersonalFieldset-text">
                { I18n.t('newPaybar.companyPlanText', { maxEmployees: this.props.maxEmails }) }
              </TextNode>
            </label>

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { I18n.t('newPaybar.company.company') }
              </TextNode>
              <FormInput className="newPaybarPersonalFieldset-input"
                name="company"
                type="text"
                autoComplete="organization"
                required
                onValidityChange={ this.onInputValidityChange }
                onInput={ this.onInput }
                value={ this.rememberedFields.company }
                initialValue={ this.rememberedFields.company }
                ref={ ref => this.fieldRefs.company = ref}
              />
            </label>

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { I18n.t('newPaybar.company.emails') }
              </TextNode>
              <NewPaybarEmployeesTextarea
                name="emails"
                userEmail={ this.props.userEmail }
                maxEmails={ this.props.maxEmails }
                onValidityChange={ this.onInputValidityChange }
                onInput={ this.onInput }
                value={ this.rememberedFields.emails }
                initialValue={ this.rememberedFields.emails }
                ref={ ref => this.fieldRefs.emails = ref }/>
            </label>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = {
  setFormValidity,
  addDirtyForm,
  removeDirtyForm,
}

const mapStateToProps = ({ newPaybar }) => {
  const emails = (newPaybar.subscription.employee_emails || []).join(',\n')
  const maxEmails = newPaybar.subscription.max_employees || 0
  const company = newPaybar.subscription.company || ''
  const shouldClearOnReload = CLEAR_ON_RELOAD_FORMS.includes(newPaybar.type)

  return {
    subscription: newPaybar.subscription,
    userName: newPaybar.userName,
    userEmail: newPaybar.userEmail,
    isVisible: newPaybar.isVisible,
    type: newPaybar.type,
    company,
    emails,
    maxEmails,
    shouldClearOnReload,
    isAgreedToTerms: newPaybar.isAgreedToTerms,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewPaybarPersonalFieldset)
