import { connect } from 'preact-redux'
import cx from 'classnames'
import { ExtendableDropdown } from '../form'

import authors from 'lib/current/sovietAuthors'

import { setAuthor } from './sovietActions'

const authorSecondName = name => name.includes('.') ? name : (name.split(' ')[1] || name)

class SovietAuthorSelector extends ExtendableDropdown {
  constructor(props) {
    super(props)

    this.wrapperClassName = 'sovietAuthorSelector'

    this.setAuthor = this.setAuthor.bind(this)
  }

  setAuthor(event, author) {
    event.preventDefault()
    event.stopPropagation()

    this.props.setAuthor({
      authorName: author,
      authorGenitiveName: authors[author].authorGenitiveName,
      authorAvatarUrl: authors[author].authorAvatarUrl,
      authorArchiveUrl: authors[author].authorArchiveUrl,
      authorCaption: authors[author].authorCaption,
      keyColor: authors[author].keyColor,
      keyUnderlineColor: authors[author].keyUnderlineColor,
      bgColor: authors[author].bgColor,
    })

    this.hide()
  }

  get currentItem() {
    const { authorName } = this.props
    const author = authors[authorName]
    const itemStyle = { backgroundImage: `url(${author.authorAvatarUrl})` }

    return (
      <div className="sovietAuthorSelector-current" style={ itemStyle }>
        { authorSecondName(authorName) }
      </div>
    )
  }

  get dropdownItems() {
    return Object.keys(authors)
      .filter(author => authors[author].weekDay.length)
      .map((author) => {
        const itemClasses = cx(
          'dropdown-item',
          { is__active: this.props.authorName === author }
        )

        return (
          <div className={ itemClasses } onClick={ (e) => this.setAuthor(e, author) }>
            { authorSecondName(author) }
          </div>
        )
      })
  }
}

const mapStateToProps = ({ soviet }) => {
  return { authorName: soviet.authorName }
}

const mapDispatchToProps = { setAuthor }

export default connect(mapStateToProps, mapDispatchToProps)(SovietAuthorSelector)
