import { createStore as createReduxStore, applyMiddleware } from 'redux'
import Cookies from 'js-cookie'

import newPaybarReducer from './newPaybarReducer'
import { getBasementItemToOpen } from './reducerHelpers'
import getUnconfirmedPurchases from 'products/getUnconfirmedPurchases'
import getDerivedStateFromUrl from './stateFromUrl'
import isSubscriptionCancelled from './isSubscriptionCancelled'
import FormFieldsStorage from './formFieldsStorage'
import purchaseIntentMiddleware from './newPaybarPurchaseIntentMiddleware'

const formFieldsStorage = new FormFieldsStorage()
const rememberedFields = formFieldsStorage.get()
rememberedFields.isAgreedToTerms = rememberedFields.isAgreedToTerms || {}
rememberedFields.isStudent = rememberedFields.isStudent || {}

const DEFAULT_STATE = {
  isVisible: false,
  screen: 'initial',
  type: 'self',
  activeBasementItem: undefined,
  isValid: false,
  isUserAuthenticated: false,
  hasFormContent: false,
  dirtyForms: [],
  hasUpdatedSubscription: false,
  paymentType: rememberedFields.paymentType || 'oneTime', // or 'monthly'
  enrollment: rememberedFields.enrollment || 'default', // or 'grant'
  participantType: rememberedFields.participantType || 'self', // or 'other'
  companyPaymentType: rememberedFields.companyPaymentType || 'card', // or 'invoice'
  isAgreedToTerms: {
    self: !!rememberedFields.isAgreedToTerms.self,
    gift: !!rememberedFields.isAgreedToTerms.gift,
    company: !!rememberedFields.isAgreedToTerms.company,
  },
  isStudent: {
    self: !!rememberedFields.isStudent.self,
    gift: !!rememberedFields.isStudent.gift,
    company: !!rememberedFields.isStudent.company,
  },
}

export const getDefaultState = (subscription) => {
  if (!subscription || !subscription.user || !subscription.user.id) {
    return DEFAULT_STATE
  }

  const alreadySubscribed = !!subscription.subscription.id ||
    isSubscriptionCancelled(subscription.subscription)

  return {
    ...DEFAULT_STATE,
    screen: alreadySubscribed ? 'subscription' : 'purchase',
    activeBasementItem: getBasementItemToOpen(subscription),
    isUserAuthenticated: true,
    userEmail: subscription.user.email,
    userName: subscription.user.name,
    userCity: subscription.user.city,
    userProfession: subscription.user.profession,
    userOrganization: subscription.user.jobPlace,
    userTel: subscription.user.tel,
    userTelegram: subscription.user.telegram,
    userFirstName: subscription.user.firstName,
    userCardNumber: subscription.user.cardNumber,
    userPaymentMethod: subscription.user.paymentMethod || 'default',
    cardIframeUrl: `/pay/initcard/embedded/?locale=${I18n.locale}`,
    participantType: alreadySubscribed ? 'other' : DEFAULT_STATE.participantType,
  }
}

const getDerivedStateFromCookies = (props) => {
  const userEmail = Cookies.get('email')
  const accessToken = Cookies.get('paybarAccessToken')
  const unconfirmedPurchases = getUnconfirmedPurchases()
  const productId = props.subscription.product_id

  if (!userEmail || !accessToken || !unconfirmedPurchases.includes(productId)) return {}

  return {
    userEmail: userEmail,
    accessToken: accessToken,
    cardIframeUrl: `/pay/initcard/embedded/?locale=${I18n.locale}`,
    screen: 'subscription',
    type: 'self',
    hasSubscribedSelf: true,
    noticeType: 'emailSent',
  }
}

export function createStore(props) {
  const { isProduction } = window.application
  const devtoolsExtension = isProduction ? undefined : window.__REDUX_DEVTOOLS_EXTENSION__

  return createReduxStore(
    newPaybarReducer,
    {
      newPaybar: {
        ...props,
        ...getDerivedStateFromCookies(props),
        ...getDerivedStateFromUrl(props),
      },
    },
    applyMiddleware(purchaseIntentMiddleware),
    devtoolsExtension && devtoolsExtension()
  )
}
