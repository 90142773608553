const defaultProductId = () => {
  const hasSubscription = !!window.productSubscription

  return hasSubscription ? window.productSubscription.subscription.product_id : ''
}

const getLocalStorageItemKeyFromProductId = (productId = defaultProductId()) => {
  return `${productId}.paybarRememberedFields`
}

class FormFieldsStorage {
  constructor(key) {
    this.key = key || getLocalStorageItemKeyFromProductId()
  }

  save(rememberedFields) {
    try {
      localStorage.setItem(this.key, JSON.stringify(rememberedFields))
    } catch (e) {
      console.warn('Failed to save remembered fields: ', e)
    }
  }

  clear() {
    try {
      localStorage.removeItem(this.key)
    } catch (e) {
      console.warn('Failed to clear remembered fields: ', e)
    }
  }

  get() {
    try {
      const rememberedFields = localStorage.getItem(this.key)

      return JSON.parse(rememberedFields) || {}
    } catch (e) {
      console.warn('Failed to get remembered fields: ', e)

      return {}
    }
  }
}

module.exports = FormFieldsStorage
