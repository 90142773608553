import { connect } from 'preact-redux'
import cx from 'classnames'

import { Caption, TextNode } from '../../textNodes'
import { FormInput, FormCheckbox } from '../../form'

import NewPaybarFieldsetHeading from '../newPaybarFieldsetHeading'
import NewPaybarPersonalFieldsetBase from '../newPaybarPersonalFieldsetBase'
import NewPaybarPaymentTypeSwitch from './newPaybarPaymentTypeSwitch'
import NewPaybarParticipantTypeSwitch from './newPaybarParticipantTypeSwitch'
import NewPaybarTelegramInput from '../newPaybarTelegramInput'

import {
  hasSelfSubscription,
  hasSelfSubscriptionFromCompany,
} from './courseHelpers'

import {
  addDirtyForm,
  removeDirtyForm,
  setFormValidity,
  setCompanyPaymentType,
  setParticipantType,
  setParticipantStudent,
} from '../newPaybarActions'
import I18n from 'i18n-js'

class NewPaybarPersonalFieldset extends NewPaybarPersonalFieldsetBase {
  constructor(props) {
    super(props)

    this.validityStates = {
      payerName: !!this.props.userName,
      presenteeName: false,
      presenteeEmail: false,
      organization: !!this.props.userOrganization,
      presenteeOrganization: false,
      profession: !!this.props.userProfession,
      presenteeProfession: false,
      tel: false,
      telegram: false,
      presenteeTel: false,
      presenteeTelegram: false,
      companyTitle: false,
      companyEmail: false,
      companyCEO: false,
      instituteTitle: false,
      instituteTel: false,
      presenteeInstituteTitle: false,
      presenteeInstituteTel: false,
    }

    this.validityMaps = {
      self: ['payerName', 'profession', 'organization', 'tel'],
      gift: ['payerName', 'presenteeName', 'presenteeEmail', 'presenteeTel'],
      company: ['payerName', 'tel', 'companyTitle', 'companyEmail', 'companyCEO'],
      companyOther: ['payerName', 'presenteeName', 'presenteeEmail', 'presenteeTel', 'companyTitle', 'companyEmail', 'companyCEO'],
    }

    this.studentValidityMaps = {
      self: ['instituteTitle', 'instituteTel'],
      gift: ['presenteeInstituteTitle', 'presenteeInstituteTel'],
      company: ['instituteTitle', 'instituteTel'],
      companyOther: ['presenteeInstituteTitle', 'presenteeInstituteTel'],
    }

    this.rememberedHiddenInputs = ['participantType', 'companyPaymentType']

    this.setSelfParticipantType = this.setSelfParticipantType.bind(this)
    this.setOtherParticipantType = this.setOtherParticipantType.bind(this)
    this.handleParticipantStudentChange = this.handleParticipantStudentChange.bind(this)
    this.setCardCompanyPayment = this.setCardCompanyPayment.bind(this)
    this.setInvoiceCompanyPayment = this.setInvoiceCompanyPayment.bind(this)
  }

  get isValid() {
    const { hasTerms, type, participantType } = this.props

    if (hasTerms && !this.isAgreedToTerms) return false

    const key = (type === 'company' && participantType === 'other') ? 'companyOther' : type
    const validityMaps = [
      ...this.validityMaps[key],
      ...(this.isStudent ? this.studentValidityMaps[key] : []),
    ]

    return validityMaps.every(field => this.validityStates[field])
  }

  get isStudent() {
    return this.props.isStudent[this.props.type]
  }

  componentDidUpdate() {
    this.props.setFormValidity({ isValid: this.isValid })
    this.rememberedHiddenInputs.forEach(input => this.remember(input))
    this.saveAgreedToTerms()
    this.saveIsStudent()
  }

  setCardCompanyPayment() {
    this.props.setCompanyPaymentType('card')
  }

  setInvoiceCompanyPayment() {
    this.props.setCompanyPaymentType('invoice')
  }

  setSelfParticipantType() {
    this.props.setParticipantType('self')
  }

  setOtherParticipantType() {
    this.props.setParticipantType('other')
  }

  handleParticipantStudentChange({ value }) {
    this.props.setParticipantStudent(value, this.props.type)
  }

  render() {
    const isGift = this.props.type === 'gift'
    const isSelf = hasSelfSubscription(this.props.subscription) || hasSelfSubscriptionFromCompany(this.props.subscription)
    const isCompany = this.props.type === 'company'
    const isCompanyForOtherParticipant = isCompany && (this.props.participantType === 'other' || isSelf)

    return (
      <div className="newPaybarPersonalFieldset" ref={ this.bindEl }>
        { !isCompany &&
          <NewPaybarFieldsetHeading
            text={ I18n.t(`newPaybar.course.participant.${this.props.type}`) } />
        }

        { isCompany &&
          <div className="newPaybarPersonalFieldset-row is__switch">
            <NewPaybarParticipantTypeSwitch
              participantType={ this.props.participantType }
              hasSelfSubscription={ isSelf }
              onSetSelf={ this.setSelfParticipantType }
              onSetOther={ this.setOtherParticipantType } />

            <input type="hidden" name="participantType" value={ this.props.participantType } />
          </div>
        }

        { !this.props.userName &&
          <div className="newPaybarPersonalFieldset-row is__topLevelRow">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t(`newPaybar.course.name.${this.props.type}`) }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="payerName"
              type="text"
              autoComplete="name"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.participantName }
              ref={ ref => this.fieldRefs.participantName = ref } />
          </div>
        }

        { (isGift || isCompanyForOtherParticipant) &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.labels.fullName') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeName"
              type="text"
              required
              onValidityChange={ this.onInputValidityChange }
              onInput={ this.onInput }
              value={ this.rememberedFields.presenteeName }
              initialValue={ this.rememberedFields.presenteeName }
              ref={ ref => this.fieldRefs.presenteeName = ref } />
          </label>
        }

        { (isGift || isCompanyForOtherParticipant) &&
          <label
            className={ cx('newPaybarPersonalFieldset-row', { has__caption: isCompanyForOtherParticipant }) }>

            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.gift.presenteeEmail') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="presenteeEmail"
                type="email"
                required
                onValidityChange={ this.onInputValidityChange }
                onInput={ this.onInput }
                value={ this.rememberedFields.presenteeEmail }
                initialValue={ this.rememberedFields.presenteeEmail }
                ref={ ref => this.fieldRefs.presenteeEmail = ref } />

              { isCompanyForOtherParticipant && (
                <Caption
                  html={ I18n.t('newPaybar.course.companyOtherEmailCaption') } />
              ) }
            </div>
          </label>
        }

        { !isGift && !isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row has__inlineCaption">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.tel') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="tel"
                type="tel"
                required
                onInput={ this.onInput }
                onValidityChange={ this.onInputValidityChange }
                initialValue={ this.rememberedFields.tel || this.props.userTel }
                ref={ ref => this.fieldRefs.tel = ref } />
              <Caption html={ I18n.t('newPaybar.school.telCaption') } />
            </div>
          </label>
        }

        { !isGift && !isCompanyForOtherParticipant &&
          <NewPaybarTelegramInput
            name="telegram"
            onInput={ this.onInput }
            onValidityChange={ this.onInputValidityChange }
            initialValue={ this.rememberedFields.telegram || this.props.userTelegram }
            ref={ ref => this.fieldRefs.telegram = ref } />
        }

        { (isGift || isCompanyForOtherParticipant) &&
          <label className="newPaybarPersonalFieldset-row has__inlineCaption">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.tel') }
            </TextNode>
            <div className="newPaybarPersonalFieldset-inputWithCaption">
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="presenteeTel"
                type="tel"
                required
                onInput={ this.onInput }
                onValidityChange={ this.onInputValidityChange }
                initialValue={ this.rememberedFields.presenteeTel }
                ref={ ref => this.fieldRefs.presenteeTel = ref } />
              <Caption html={ I18n.t('newPaybar.school.telCaption') } />
            </div>
          </label>
        }

        { (isGift || isCompanyForOtherParticipant) &&
          <NewPaybarTelegramInput
            name="presenteeTelegram"
            key="presenteeTelegram"
            onInput={ this.onInput }
            onValidityChange={ this.onInputValidityChange }
            initialValue={ this.rememberedFields.presenteeTelegram }
            ref={ ref => this.fieldRefs.presenteeTelegram = ref } />
        }

        { !isGift && !isCompanyForOtherParticipant && !this.props.userOrganization &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.organization') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="organization"
              type="text"
              autoComplete="organization-title"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.organization }
              ref={ ref => this.fieldRefs.position = ref } />
          </label>
        }

        { !isGift && !isCompanyForOtherParticipant && !this.props.userProfession &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="profession"
              type="text"
              autoComplete="organization-title"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.profession }
              ref={ ref => this.fieldRefs.profession = ref } />
          </label>
        }

        { isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.organization') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeOrganization"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeOrganization }
              ref={ ref => this.fieldRefs.presenteeOrganization = ref } />
          </label>
        }

        { isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.school.profession') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeProfession"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeProfession }
              ref={ ref => this.fieldRefs.presenteeProfession = ref } />
          </label>
        }

        <div className="newPaybarPersonalFieldset-row is__checkbox">
          <FormCheckbox
            name="isStudent"
            value={ this.props.isStudent[this.props.type] }
            text={ I18n.t(`newPaybar.course.studentCheckbox.${this.props.type}`) }
            onChange={ this.handleParticipantStudentChange } />
        </div>

        { this.isStudent && !isGift && !isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTitle') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="instituteTitle"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.instituteTitle }
              value={ this.rememberedFields.instituteTitle }
              ref={ ref => this.fieldRefs.instituteTitle = ref } />
          </label>
        }

        { this.isStudent && !isGift && !isCompanyForOtherParticipant &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTel') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="instituteTel"
              type="tel"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.instituteTel }
              value={ this.rememberedFields.instituteTel }
              ref={ ref => this.fieldRefs.instituteTel = ref } />
          </label>
        }

        { this.isStudent && (isGift || isCompanyForOtherParticipant) &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTitle') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeInstituteTitle"
              type="text"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeInstituteTitle }
              value={ this.rememberedFields.presenteeInstituteTitle }
              ref={ ref => this.fieldRefs.presenteeInstituteTitle = ref } />
          </label>
        }

        { this.isStudent && (isGift || isCompanyForOtherParticipant) &&
          <label className="newPaybarPersonalFieldset-row">
            <TextNode className="newPaybarPersonalFieldset-label">
              { I18n.t('newPaybar.course.instituteTel') }
            </TextNode>
            <FormInput
              className="newPaybarPersonalFieldset-input"
              name="presenteeInstituteTel"
              type="tel"
              required
              onInput={ this.onInput }
              onValidityChange={ this.onInputValidityChange }
              initialValue={ this.rememberedFields.presenteeInstituteTel }
              value={ this.rememberedFields.presenteeInstituteTel }
              ref={ ref => this.fieldRefs.presenteeInstituteTel = ref } />
          </label>
        }

        { isCompany &&
          <div class="newPaybarPersonalFieldset-section">
            <NewPaybarFieldsetHeading text={ I18n.t('newPaybar.course.payer') } />

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { I18n.t('newPaybar.course.companyTitle') }
              </TextNode>
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="companyTitle"
                type="text"
                autoComplete="organization"
                required
                onInput={ this.onInput }
                onValidityChange={ this.onInputValidityChange }
                initialValue={ this.rememberedFields.companyTitle }
                ref={ ref => this.fieldRefs.companyTitle = ref } />
            </label>

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { I18n.t('newPaybar.course.companyCEO') }
              </TextNode>
              <FormInput
                className="newPaybarPersonalFieldset-input"
                name="companyCEO"
                type="text"
                placeholder={ I18n.t('newPaybar.course.companyCEOPlaceholder') }
                autoComplete="off"
                required
                onInput={ this.onInput }
                onValidityChange={ this.onInputValidityChange }
                initialValue={ this.rememberedFields.companyCEO }
                ref={ ref => this.fieldRefs.companyCEO = ref }
              />
            </label>

            <label className="newPaybarPersonalFieldset-row">
              <TextNode className="newPaybarPersonalFieldset-label">
                { I18n.t('newPaybar.course.companyEmail') }
              </TextNode>
              <div className="newPaybarPersonalFieldset-inputWithCaption">
                <FormInput
                  className="newPaybarPersonalFieldset-input"
                  name="companyEmail"
                  type="email"
                  autoComplete="off"
                  required
                  onInput={ this.onInput }
                  onValidityChange={ this.onInputValidityChange }
                  initialValue={ this.rememberedFields.companyEmail }
                  ref={ ref => this.fieldRefs.companyEmail = ref } />

                <Caption html={ I18n.t('newPaybar.course.companyEmailCaption') } />
              </div>
            </label>

            <div className="newPaybarPersonalFieldset-row is__switch has__caption">
              <NewPaybarPaymentTypeSwitch
                paymentType={ this.props.companyPaymentType }
                onSetCard={ this.setCardCompanyPayment }
                onSetInvoice={ this.setInvoiceCompanyPayment } />

              <input type="hidden" name="companyPaymentType" value={ this.props.companyPaymentType } />
            </div>
          </div>
        }
      </div>
    )
  }
}

const mapDispatchToProps = {
  setFormValidity,
  addDirtyForm,
  removeDirtyForm,
  setCompanyPaymentType,
  setParticipantType,
  setParticipantStudent,
}

const mapStateToProps = ({ newPaybar }) => {
  return {
    userName: newPaybar.userName,
    userProfession: newPaybar.userProfession,
    userOrganization: newPaybar.userOrganization,
    userTel: newPaybar.userTel,
    userTelegram: newPaybar.userTelegram,
    isVisible: newPaybar.isVisible,
    isPreorder: newPaybar.isPreorder,
    companyPaymentType: newPaybar.companyPaymentType,
    participantType: newPaybar.participantType,
    isStudent: newPaybar.isStudent,
    subscription: newPaybar.subscription,
    product: newPaybar.product,
    isAgreedToTerms: newPaybar.isAgreedToTerms,
    productDetails: newPaybar.productDetails,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPaybarPersonalFieldset)
